import React, { useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReportedListings from '../../components/notificationComponent/reportedListings'
import ReportedUsers from '../../components/notificationComponent/reportedUsers';
import SystemNotifications from '../../components/notificationComponent/systemNotifications';
import style from '../notifications/notification.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTabAction } from '../../redux/Actions';


const Notification = () => {

  const dispatch = useDispatch();
  const tabDispatch = (key)=>{
     let tabKey = key.target.dataset.rrUiEventKey
     dispatch(ActiveTabAction(tabKey))
  }
  const activeTab = useSelector((state) => state.activetab);
  //Notification fix
  const state = useSelector((state)=>state.getGlobalNotificationStatus)
 
  return (
    <div>
      <div className="row notificationTabWrapper">
        <div className={"col-md-12" + " " + style.notificationTab}>
          <Tabs defaultActiveKey={activeTab} onClick={(key) => tabDispatch(key)} justify>
            <Tab eventKey='rl' 
               title={                
                <React.Fragment>
                   {state.ListingReports!==1&&<span className='notificationiconReportTableheader'></span>}
                   Reported Listings
                </React.Fragment>
              }
            >
          
              <ReportedListings />
            
            </Tab>
            <Tab 
               eventKey="ru" 
               title={
                <React.Fragment>
                    {state.UserReports!==1&&<span className='notificationiconReportTableheader'></span>}
                   Reported Users
                </React.Fragment>
              }
              >
              <ReportedUsers />
            </Tab>
            <Tab eventKey="sn" title={   <React.Fragment>
                    {state.Notifications!==1&&<span className='notificationiconReportTableheader'></span>}
                   &nbsp; System Notifications
                </React.Fragment>}>
              <SystemNotifications />
            </Tab> 
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Notification