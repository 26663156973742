import { useState } from 'react';
import Style from './database.module.css';
import { Form, FormControl } from 'react-bootstrap';
import { IoIosArrowUp, IoMdSearch } from "react-icons/io";
import AddNewCategory from '../../../assets/images/add_green.png';
import DatabaseTable from '../../../components/table_components/databaseTable';
import { useDispatch, useSelector } from 'react-redux';
import { activeCategoryPopup } from '../../../redux/Actions';
import { IoIosArrowDown } from "react-icons/io";

const Database = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [activeDropDown, setActivedropDown] =useState(false);
    const [SelectActiveValue, setSelectActiveValue] = useState('');
    const [CustomActive, setCustomActive] = useState(true);
    const [MasterActive, setMasterActive] = useState(true);

    const selectHandleChange =(e)=>{
        let value = e.target.value
         if(value === 'master'){
             setMasterActive(!MasterActive)
         }
         if(value === 'custom'){
            setCustomActive(!CustomActive)
         }

    }
    return (
        <div className={Style.database}>
            <div className="row">
                <div className="col-md-12 mb-4 mt-3">
                    <div className="col-md-8"><h2>Database</h2></div>
                </div>
                <div className="col-md-12">
                    <div className={Style.filterWrapper + ' ' + 'row align-items-center'}>
                        <div className="col-md-6">
                            <Form>
                                <div className={Style.search}>
                                    <FormControl
                                        placeholder="Search"
                                        value = {searchValue}
                                        onChange={(e)=>setSearchValue(e.target.value)}
                                    />
                                    <IoMdSearch />
                                </div>
                            </Form>
                        </div>
                        <div className={Style.filterView + ' ' + 'col-md-3 text-end'}>
                            <button 
                               onClick={()=>dispatch(activeCategoryPopup(true))}
                             >
                            <img src={AddNewCategory} alt="Add New Category" />Add New Category</button> 
                        </div>
                        <div className={Style.generateSelect + ' ' + 'col-md-3'} style={{position:"relative"}}> 
                             <label className="arrowDown selectionBox"
                                onClick={()=>setActivedropDown(!activeDropDown)}
                             >View Options{activeDropDown?<IoIosArrowUp/>:<IoIosArrowDown />}</label>
                             {activeDropDown&&<ul>
                                <li>
                                    <input 
                                       type="checkbox" 
                                       id="master" 
                                       value="master" 
                                       checked={MasterActive}
                                       onChange={(e)=>selectHandleChange(e)}
                                    />
                                    <label htmlFor="join">View only Masterlist</label>
                                </li>
                                <li>
                                    <input 
                                        type="checkbox" 
                                        id="custom" 
                                        value="custom" 
                                        checked={CustomActive}
                                        onChange={(e)=>selectHandleChange(e)}
                                    />
                                    <label htmlFor="join">View only Custom</label>
                                </li>
                             </ul>}
                        </div>
                    </div>
                </div>
                <DatabaseTable 
                   searchCurrentValue = {searchValue}
                   CustomActive = {CustomActive}
                   MasterActive={MasterActive}
                />
            </div>
        </div>
    )
}

export default Database