import { activeCategoryPop, activeReportNotiList, activeTab, activeUserListing, activeUserNotiList, getuserMessageId,activeGlobalNotification,getGlobalNotification } from "./types"

export const GetUserMessageIdAction = (userId,userName)=>{
    return {
        type:getuserMessageId,
        payLoad:{userId,userName}
    }
} 
export const ActiveTabAction =(tabkey)=>{
    return {
        type:activeTab,
        payLoad:tabkey
    }
}
export const ActiveReportListNotification =(reportedListNoti)=>{
    return{
        type:activeReportNotiList,
        payLoad:{"reportedListNoti":reportedListNoti}
    }
}
export const ActiveReportUserNotification =(reportedUserNoti)=>{
    return{
        type:activeUserNotiList,
        payLoad:{'reportedUserNoti':reportedUserNoti}
    }
}
export const ActiveUserListingTab =(tabkey)=>{
    return{
        type:activeUserListing,
        payLoad:tabkey
    }
}
export const activeCategoryPopup=(active)=>{
    return {
        type:activeCategoryPop,
        payLoad:active
    }
}
 //Notification fix
// export const ActiveReportSystemNotification =(reportedSystemNoti)=>{
//     return{
//         type:activeSystemNotiList,
//         payLoad:{'reportedSystemNoti':reportedSystemNoti}
//     }
// }
export const ActiveGlobalNotification =(globalNotification)=>{
   // console.log('----',globalNotification);
    return{
        type:activeGlobalNotification,
        payLoad:{'globalNotification':globalNotification}
    }
}
export const GetGlobalNotification =(globalNotification)=>{
    //console.log('----',globalNotification);
    return{
        type:getGlobalNotification,
        payLoad:{'getglobalNotification':globalNotification}
    }
}
 //Notification fix

