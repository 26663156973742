import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({MonthLabels,title,graphData,UserAggregateData})=>{
let realData = graphData&&graphData.flatMap(Object.values);
if(title === 'Total Number of Users'){
  if(UserAggregateData!==null){
    let x = Object.values(UserAggregateData)
    realData = [...realData,...x]  
  }
}
    const labels = MonthLabels;
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          fontSize: 20,
          text: title,
          position:"bottom"
        }
      },
      scales: {
        // to remove the labels
        x: {
          ticks: {
            display: true,
          },
    
          // to remove the x-axis grid
          grid: {
            drawBorder: true,
            display: false,
          },
        },
        // to remove the y-axis labels
        y: {
          ticks: {
            display: true,
            beginAtZero: true,
          },
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
      },
    };
    let data = {
        labels,
        datasets: [
          {
            //label: 'Dataset 1',
            data:realData,
            backgroundColor: '#5BA15B',
          }
        ],
      };
    return(
        <Bar options={options} data={data} height="200"/>
    )
}
export default BarGraph;