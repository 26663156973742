import React from 'react'
import './App.css';
import Header from './components/header/header';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './pages/dashboard/dashboard';
import Users from './pages/users/users';
import Notification from './pages/notifications/notification'; 
import Login from './pages/login/login';
import UserMessages from './pages/userMessages/userMessages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation'; 
import { TokenExpiry} from './pages/utils/tokenExpiry';
import ActivityLog from './pages/tools/activityLog/activityLog';
import UserListings from './pages/tools/userListings/userListings';
import ManageAdmin from './pages/tools/manageAdmin/manageAdmin'; 
import Database from './pages/tools/database/database'; 
import Backup from './pages/tools/backup/backup';
import Forgotpassword from './pages/forgotpassword/forgotpassword';
import ForgotpasswordSuccess from './pages/forgotpassword/forgotPasswordSuccess';
import SetyourPassword from './pages/setyourpassword/setyourpassword';
import { AppUrl } from './components/utils/utils';
import PageNotFound from './pages/pageNotfound/pageNotFound';

const App = () => {
  let location = useLocation();
  let currentUrl = location.pathname;
  let currentEndUrl = currentUrl.split('/')[1];
  return (
    <React.Fragment>
      {currentUrl !== '/'&&!AppUrl.includes(currentEndUrl)&&!TokenExpiry(localStorage.getItem('adminTokenExpiry'))&&<Header />}
      <div 
         className={currentUrl!=="/userMessages"?"container":"container-fluid"}
         style={{
             backgroundColor:currentUrl==="/userMessages"?"#F3F3F3":"unset",
         }}
      >
        <div 
            className='mainWrapper'
            style={{
              paddingTop:currentUrl==="/userMessages"&&"0px",
             }}
        >
          <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path="/forgotpassword" element={<Forgotpassword/>}/>
            <Route path="/forgotpasswordSuccess" element={<ForgotpasswordSuccess/>}/>
            <Route path="/setyourPassword" element={<SetyourPassword/>}/>
            {!TokenExpiry(localStorage.getItem('adminTokenExpiry'))?<><Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/userMessages" element = {<UserMessages/>}/>
            <Route path="/notification" element={<Notification/>}/>
            <Route path="/activityLog" element={<ActivityLog/>}/>
            <Route path="/userListings" element={<UserListings/>}/>
            <Route path="/database" element={<Database/>}/>
            <Route path="/backup" element={<Backup/>}/> 
            <Route path="/manageAdmin" element={<ManageAdmin/>}/></>:
            <Route path="*" element={<Navigate replace to="/" />} />}
            <Route path="/notFound" element={<PageNotFound />} />
            <Route
							path="*"
							element={
								<Navigate to="notFound" state={{ from: currentUrl }} replace />
							}
						/>
          </Routes>
        </div>
        <ToastContainer/>
      </div> 
   
    </React.Fragment>

  )
}

export default App;
