import React, { useState } from 'react'
import Style from './forgotpassword.module.css';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { ForgotPasswordApi } from '../../services/adminApis';
import { useNavigate } from 'react-router-dom';


const Forgotpassword = () => {
    let navigate = useNavigate();
    const [EmailInput, setEmailInput] = useState('');
    const { mutate: sendEmailFunc } = useMutation('forgot_password', ForgotPasswordApi, {
        onSuccess: (data) => {
            toast.success(data.message);
            navigate('/forgotpasswordSuccess');
        },
        onError: (error) => {
            toast.error(error.response.data.message);
        }
    })
    const forgotPasswordSuccess = () => {
        const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (EmailInput === '') {
            toast.error('Please enter Email Id.');
            return;
        }
        else if (!regex_email.test(EmailInput)) {
            toast.error('Please enter valid Email Id.');
            return;
        }
        else {
            let obj = {
                "email": EmailInput
            }
            sendEmailFunc(obj)
        }
    }

    return (
        <div className={Style.forgotWrapper}>
            <div className="row">
                <div className="col-md-12 mt-3">
                    <h3>Forgot Password</h3>
                    <p>Enter your registered email address below. A password reset link will be sent to this email shortly.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-4'>
                    <label className='form_label'>Email</label>
                    <input
                        autoComplete='off'
                        type="text"
                        name="userName"
                        className='form_control'
                        value={EmailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                    />
                </div>
                <div className='col-6' style={{ textAlign: "right" }}>
                    <button
                        className='btn_primary w-100'
                        onClick={() => navigate('/')}
                    >Back</button>
                </div>
                <div className='col-6'>
                    <button
                        className='btn_primary w-100'
                        onClick={forgotPasswordSuccess}
                        disabled={EmailInput !== '' ? false : true}
                        style={{ backgroundColor: EmailInput === '' && "#A5A5A5" }}
                    >Continue</button>
                </div>              
            </div>
        </div>
    )
}
export default Forgotpassword