import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='py-4 text-center'><h1>404 Page not found</h1>
        <button className='addNewSubcat mt-3' onClick={()=>navigate('/')}>Go To Home Page</button>
    </div>
  )
}

export default PageNotFound