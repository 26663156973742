import React, { useState } from 'react'
import Style from './user_messages.module.css';
import { IoMdSearch } from "react-icons/io";

import { useQuery } from 'react-query';
import { GetAllMessages } from '../../services/adminApis';
import ChatUsers from '../../components/chatUsers/chatUsers';
import LoaderComponent from '../../components/loader/loader';
import Messages from '../../components/chatUsers/messages';

const UserMessages = () => {
   const [searchUserchat, setSearchUserChat] = useState('');
  
   const { data: getMessages, isFetched, isLoading } = useQuery('getAllMessages', GetAllMessages, {
      retry: 0,
      refetchInterval: 10000
   });
   return (
      <div className={Style.chatWrapper}>
         <div className='row'>
            <div className='col-5 py-4 leftBox'>
               <div className={Style.userSearch}>
                  <span><IoMdSearch /></span>
                  <input
                     type="text"
                     placeholder='Search for users...'
                     onChange={(e) => setSearchUserChat(e.target.value)}
                     value={searchUserchat}
                  />
               </div>

               <div className={Style.userListswrapper}>
                  {isFetched ? <ChatUsers
                     searchChat={searchUserchat}
                     usersList={getMessages}
                  /> : <LoaderComponent />}
               </div>
            </div>
            <Messages />
         </div>
      </div>
   )
}

export default UserMessages