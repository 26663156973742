import { activeCategoryPop, activeReportNotiList, activeTab, activeUserListing, activeUserNotiList, getuserMessageId,activeGlobalNotification,getGlobalNotification } from "./types";

const initialState ={
    userId:'',
    userName:'',
    activetab:'rl',
    activReportListNot:false,
    activeReportUserNoti:false,
    activeTabUserListing:'sl',
    activeCategory:false,
    //Notification fix
    activeGlobalNotificationStatus:false,
   getGlobalNotificationStatus:[]
}
const Reducer = (state = initialState, action)=>{
     let userObj = action.payLoad;
     //console.log('action--',userObj.globalNotification);
     switch (action.type) {
        case getuserMessageId:
            return{
                ...state,
                userId:userObj.userId,
                userName:userObj.userName
            }
        case activeTab:

            return {
                ...state,
                activetab:action.payLoad,
            }
        
        case activeReportNotiList:
            return{
                ...state,
                activReportListNot:userObj.reportedListNoti
            }
        case activeUserNotiList:
            return{
                ...state,
                activeReportUserNoti:userObj.reportedUserNoti

            }
              //Notification fix
            case activeGlobalNotification:
            return{
                ...state,
                activeGlobalNotificationStatus:userObj.globalNotification
            }
            case getGlobalNotification:
                return{
                    ...state,
                    getGlobalNotificationStatus:userObj.getglobalNotification
                }
        case activeUserListing:
            return{
                ...state,
                activeTabUserListing:action.payLoad
            }
        case activeCategoryPop:
            return{
                ...state,
                activeCategory:action.payLoad
            }
        default:
           return state
     }
}
export default Reducer;