import Modal from 'react-bootstrap/Modal';
import { IoIosArrowDown, IoIosArrowUp, IoMdCloseCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DeleteCategoriesApi, DeleteSubCategoriesApi, EditCategoriesApi, EditSubCategoriesApi, GetAllCategoriesApi } from '../../services/adminApis';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoMdSave } from "react-icons/io";
import { toast } from 'react-toastify';
import AddCategory from '../popupComponents/addCategory';
import AddSubcategoryToMaster from '../popupComponents/addSubcategoryTomaster';
import AddExistingCategoryToMaster from '../popupComponents/addcategoryToMasterList';
import AddSubCatToExistingMasterList from '../popupComponents/addSubCatToexistingMaterList';

const DatabaseTable = ({ searchCurrentValue, MasterActive, CustomActive }) => {
    let lowercaseSerachValue = searchCurrentValue.toLowerCase();
    const reduxState = useSelector((state) => state.activeCategory);
    let queryClient = useQueryClient();
    const [activeCatOptionEn, setactiveCatOptionEn] = useState(null);
    const [activeCatOptionMM, setactiveCatOptionMM] = useState(null);
    const [EditactiveCatEn, setEditactiveCatEn] = useState(null);
    const [EditactiveCatMM, setEditactiveCatMM] = useState(null);
    const [DeleteShowCat, setDeleteShowCat] = useState(false);
    const [catName, setCatName] = useState('');
    const [CurrentcatId, setCurrentcatId] = useState(null);
    const [SubCatIdEN, setSubCatIdEN] = useState(null);
    const [SubCatIdMM, setSubCatIdMM] = useState(null);
    const [EditactiveSubCatEN, SetEditactiveSubCatEN] = useState(null);
    const [EditactiveSubCatMM, setEditactiveSubCatMM] = useState(null);
    const [SubCatName, setSubCatName] = useState('');
    const [LanguageOption, setLanguageOption] = useState('')
    const [AllCategories, setAllCategories] = useState([]);

    const onHandleCatChangeEng = (e, index) => {
        const allCatList = [...AllCategories];
        const { value } = e.target;
        allCatList[index].CategoryName_EN = value;
        setAllCategories(allCatList);
    };
    const onHandleCatChangeBur = (e, index) => {
        const allCatList = [...AllCategories];
        const { value } = e.target;
        allCatList[index].CategoryName_MM = value;
        setAllCategories(allCatList);
    };


    const onHandleSubCatChangeEn = (e, subCatindex, catIndex) => {
        const allCatList = [...AllCategories];
        const { value } = e.target;
        allCatList[catIndex].SubCategories[subCatindex].CategoryName_EN = value;
        setAllCategories(allCatList);
    };

    const onHandleSubCatChangeBur = (e, subCatindex, catIndex) => {
        const allCatList = [...AllCategories];
        const { value } = e.target;
        allCatList[catIndex].SubCategories[subCatindex].CategoryName_MM = value;
        setAllCategories(allCatList);
    };

    const { isLoading: categoryLoading } = useQuery('getAllCategories', GetAllCategoriesApi, {
        onSuccess: (data) => {
            setAllCategories(data)
        }
    });

    const FilterDataLatest = useMemo(() => {
        let filteredData = AllCategories?.filter((item) => {
            return (
                item.CategoryID ||
                item.CategoryName_EN?.toLowerCase().includes(lowercaseSerachValue) ||
                item.CategoryName_MM?.toLowerCase().includes(lowercaseSerachValue) ||
                (item.SubCategories && item.SubCategories.some((itemTwo) => {
                    return (itemTwo.CategoryName_EN?.toLowerCase().includes(lowercaseSerachValue) ||
                        itemTwo.CategoryName_MM?.toLowerCase().includes(lowercaseSerachValue))

                }))
            );
        });
        return filteredData

    }, [lowercaseSerachValue, AllCategories]);
    const FinalFilter = useMemo(() => {
        if (MasterActive === true && CustomActive === false) {
            // let filterData = FilterDataLatest?.filter((item) =>
            //     item.CategoryType === "Master");
            let filterData = FilterDataLatest
            .filter((element) => 
              element.SubCategories.some((SubCategories) => SubCategories.CategoryType === "Master"))
            .map(element => {
              return Object.assign({}, element, {SubCategories : element.SubCategories.filter(SubCategories => SubCategories.CategoryType === "Master")});
          
            });
            return filterData
        }
        else if (MasterActive === false && CustomActive === true) {
            // let filterData = FilterDataLatest?.filter((item) =>
            //     item.CategoryType === "Custom");

            //     console.log("filterData",filterData)

            let filterData = FilterDataLatest
              .filter((element) => 
                element.SubCategories.some((SubCategories) => SubCategories.CategoryType === "Custom"))
              .map(element => {
                return Object.assign({}, element, {SubCategories : element.SubCategories.filter(SubCategories => SubCategories.CategoryType === "Custom")});
            
              });
            return filterData
   
        }
        else if (MasterActive === true && CustomActive === true) {
            return FilterDataLatest
        }

    }, [FilterDataLatest, MasterActive, CustomActive])

    const { mutate: editCatMutation } = useMutation('edit_cat', EditCategoriesApi, {
        onSuccess: (data) => {
            toast.success(data.message);
            setCatName('')
            queryClient.invalidateQueries('getAllCategories')
            setEditactiveCatEn(null)
            setEditactiveCatMM(null)
        },
        onError: (data) => {
            toast.error(data.message)
            setEditactiveCatEn(null)
            setCatName('')
        }
    });
    const { mutate: deleteCatFuc } = useMutation('delete_Cat', DeleteCategoriesApi, {
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries('getAllCategories')
        }
    });
    const { mutate: deleteSubCatFuc } = useMutation('delete_subCat', DeleteSubCategoriesApi, {
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries('getAllCategories')
        }
    });
    const { mutate: editSubCatMutate } = useMutation('edit_sub_cat', EditSubCategoriesApi, {
        onSuccess: (data) => {
            toast.success('Subcategory updated successfully.');
            SetEditactiveSubCatEN(null)
            setSubCatName('')
            setCatName('');
            setEditactiveSubCatMM(null)
            queryClient.invalidateQueries('getAllCategories')
        },
        onError: (data) => {
            toast.error(data.message)
        }
    })
    const ActiveCatOptionFuncEn = (catId) => {
        setactiveCatOptionEn(catId)
        setactiveCatOptionMM(null);
        setSubCatIdEN(null)
        setSubCatIdMM(null)
        setEditactiveCatEn(null);
        setEditactiveCatMM(null)
        setEditactiveCatEn(null)
        setEditactiveSubCatMM(null)
    }
    const ActiveCatOptionFuncMM = (catId) => {
        setactiveCatOptionMM(catId)
        setactiveCatOptionEn(null);
        setSubCatIdEN(null)
        setSubCatIdMM(null)
        setEditactiveCatEn(null);
        setEditactiveCatMM(null)
        setEditactiveCatEn(null)
        setEditactiveSubCatMM(null)

    }
    const editActiveCatEn = (catId) => {
        setEditactiveCatEn(catId)
        setactiveCatOptionEn(null)
        setEditactiveCatMM(null)
        setCatName('')
        setSubCatName('')
    }
    const editActiveCatMM = (catId) => {
        setEditactiveCatMM(catId)
        setactiveCatOptionMM(null)
        setEditactiveCatEn(null)
        setCatName('')
        setSubCatName('')
    }
    const closeCatEditBoxMM = () => {
        setEditactiveCatMM(null)
        setCatName('')
        setSubCatName('')
    }
    const closeCatEditBoxEN = () => {
        setEditactiveCatEn(null)
        setCatName('')
        setSubCatName('')
    }

    const EditSaveCat = (Language, catId, categoryName) => {
        let obj = {
            "catId": catId,
            "categoryName": categoryName,
            "languageID": Language
        }
        if (categoryName !== '') {
            editCatMutation(obj)
        }
        else {
            toast.error('Please enter Category.')
        }
    }

    const deleteCatFunction = (catId, popupModule) => {
        setDeleteShowCat(true)
        setCurrentcatId(catId)
        setactiveCatOptionEn(null)
        setactiveCatOptionMM(null)
        setLanguageOption(popupModule)

        setSubCatIdEN(null)
        setSubCatIdMM(null)
        setEditactiveCatEn(null)
        setEditactiveCatMM(null)
        setEditactiveSubCatMM(null)
        SetEditactiveSubCatEN(null)

    }
    const deleteCatStatus = (status) => {
        if (status === 'yes') {
            if (LanguageOption === 'cat') {
                deleteCatFuc(CurrentcatId)
            }
            else {
                deleteSubCatFuc(CurrentcatId)
            }
            setDeleteShowCat(false)
            setactiveCatOptionEn(null)
            setactiveCatOptionMM(null)
            setSubCatIdEN(null)
            setSubCatIdMM(null)
        }
        else {
            setDeleteShowCat(false)
        }
    }

    const ActiveSubCatOptionFuncEN = (subCatId) => {
        setSubCatIdEN(subCatId)
        setSubCatIdMM(null)
        setSubCatName('')
        SetEditactiveSubCatEN(null);
        setEditactiveSubCatMM(null);
        setactiveCatOptionEn(null);
        setactiveCatOptionMM(null);
        setCatName('')
    }
    const ActiveSubCatOptionFuncMM = (subCatId) => {
        setSubCatIdMM(subCatId)
        setSubCatIdEN(null)
        setSubCatName('')
        SetEditactiveSubCatEN(null);
        setactiveCatOptionEn(null);
        setactiveCatOptionMM(null);
        setCatName('')
    }
    const editActiveSubCatFuEN = (subCatId) => {
        SetEditactiveSubCatEN(subCatId);
        setSubCatIdEN(null)
        setSubCatIdMM(null)
        setEditactiveCatEn(null)
        setEditactiveCatMM(null)
        setCatName('')
        setSubCatName('')
    }
    const editActiveSubCatFuMM = (subCatId) => {
        setEditactiveSubCatMM(subCatId)
        setSubCatIdEN(null)
        setSubCatIdMM(null)
        setEditactiveCatEn(null)
        setEditactiveCatMM(null)
        setCatName('')
        setSubCatName('')
    }
    const closeSubCatEditBoxEN = () => {
        SetEditactiveSubCatEN(null);

    }
    const closeSubCatEditBoxMM = () => {
        setEditactiveSubCatMM(null)

    }
    const EditSaveSubCat = (language, subCatId, SubCategoryName) => {
        let obj = {
            "catId": subCatId,
            "categoryName": SubCategoryName,
            "languageID": language
        }
        if (SubCategoryName !== '') {
            editSubCatMutate(obj)
        }
        else {
            toast.error('Please enter Subcategory.')
        }

    }

    const [SelectedSubCatModel, setSelectedSubCatModel] = useState(false);
    const [SelectedSubCatId, setSelectedSubCatId] = useState('');
    const [selectedCatname, setSelectedCateName] = useState('')

    const [SelectedCatModel, setSelectedCatModel] = useState(false);
    const [selectedCategoryDetails, setSelectedCategoryDetails] = useState({})
    const AddNewSubcategoryToMasterFunc = (parentCatID, catName) => {
        setSelectedSubCatId(parentCatID)
        setSelectedCateName(catName)
        setSelectedSubCatModel(true)
        setactiveCatOptionEn(null);
        setactiveCatOptionMM(null);
    }
    const AddCategoryToMasterListExistingFunc = (catEN_Name, catMM_Name, catId) => {
        setSelectedCatModel(true)
        setSelectedCategoryDetails({
            "categoryName_EN": catEN_Name,
            "categoryName_MM": catMM_Name,
            "categoryID": catId
        })
        setactiveCatOptionMM(null);
        setactiveCatOptionEn(null);

    }
    const [subCategoryDetails, setSubCategoryDetails] = useState({});
    const [SubCategoryPopup, setSubCategoryPopup] = useState(false);
    const AddSubCategoryToexistingListFunc = (catId, PatentCatId, subCatEN, subCatMM) => {
        setSubCategoryPopup(true)
        setSubCategoryDetails({
            "subCategoryName_EN": subCatEN,
            "subCategoryName_MM": subCatMM,
            "parentCategoryID": PatentCatId,
            "categoryID": catId
        })
        setSubCatIdEN(null)
        setSubCatIdMM(null)
    }
    useEffect(() => {
        if (reduxState === true) {
            setactiveCatOptionMM(null);
            setactiveCatOptionEn(null);
            setSubCatIdEN(null)
            setSubCatIdMM(null)
            setEditactiveCatEn(null)
            setEditactiveCatMM(null)
            setCatName('')
            setSubCatName('')
            setEditactiveSubCatMM(null)
            SetEditactiveSubCatEN(null)
        }
    }, [reduxState])
    return (
        <div className="col-md-12 mt-4">
            <div className="listViewWrapper my-4">
                <table className="table table-bordered databaseTr">
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>CATEGORY</th>
                            <th style={{ width: "25%" }}>အမျိုးအစား</th>
                            <th style={{ width: "25%" }}>SUBCATEGORY</th>
                            <th style={{ width: "25%" }}>ခွဲ အမျိုးအစား</th>
                        </tr>
                    </thead>
                    <tbody>
                        {FinalFilter?.map((category, index) => <tr key={index}>
                            <td
                                style={{
                                    textAlign: "center",
                                    padding: "38px 0px",
                                    position: "relative",
                                    verticalAlign: "middle",
                                    backgroundColor: category?.CategoryName_MM === null ? "#FFACCD85" : category?.CategoryName_MM != null && category?.CategoryName_EN != null ? "#fff" : "#ECECEC"
                                }}>
                                {/* <span className='db-opt-close'><IoMdClose/></span> */}
                                <label
                                    className='db-td'

                                >{category?.CategoryName_EN !== null ? category.CategoryName_EN :
                                    <label className='catSmall'>This category is present only in Burmese language. Translation can be added when the custom data has to be added to the master list. Select the dropdown next to the Burmese version to do so.</label>}
                                    {category?.CategoryName_EN !== null && <>{activeCatOptionEn === category.CategoryID ?
                                        <IoIosArrowUp onClick={() => setactiveCatOptionEn(null)} /> : <IoIosArrowDown onClick={() => ActiveCatOptionFuncEn(category.CategoryID)} />}</>}</label>
                                {activeCatOptionEn === category.CategoryID && <ul className='cat-options'>
                                    <li onClick={() => editActiveCatEn(category.CategoryID)}>Edit Category</li>
                                    {category?.CategoryType !== 'Master' && <li
                                        onClick={() => AddCategoryToMasterListExistingFunc(category?.CategoryName_EN, category?.CategoryName_MM, category?.CategoryID)}
                                    >Add Category to Master List</li>}
                                    <li
                                        onClick={() => AddNewSubcategoryToMasterFunc(category?.CategoryID, category?.CategoryName_EN)}
                                    >Add Subcategory</li>
                                    <li onClick={() => deleteCatFunction(category.CategoryID, 'cat')}>Delete Category</li>
                                </ul>}
                                {EditactiveCatEn === category.CategoryID && <label
                                    className='editLabel'
                                    style={{ border: EditactiveCatEn === category.CategoryID ? "2px solid #0036CD" : 'none' }}
                                >
                                    <label className='db-opt-close-cat'><IoMdClose onClick={() => closeCatEditBoxEN()} /></label>
                                    <label className='editinput'>

                                        {/* <span>{category?.CategoryName_EN}</span> */}
                                        <input
                                            type="text"
                                            //value={catName}
                                            value={category?.CategoryName_EN}
                                            name="category"
                                            onChange={(e) => onHandleCatChangeEng(e, index)}
                                        />
                                        <IoMdSave onClick={() => EditSaveCat('EN', category.CategoryID, category?.CategoryName_EN)} />
                                    </label>
                                </label>}

                            </td>
                            <td style={{
                                textAlign: "center",
                                position: "relative",
                                verticalAlign: "middle",
                                backgroundColor: category?.CategoryName_EN === null ? "#FFACCD85" : category?.CategoryName_MM != null && category?.CategoryName_EN != null ? "#fff" : "#ECECEC"
                            }}>
                                {/* <span className='db-opt-close'><IoMdClose /></span> */}
                                <label className='db-td'>{category?.CategoryName_MM !== null ? category.CategoryName_MM :
                                    <label className='catSmall'>This category is present only in English language. Translation can be added when the custom data has to be added to the master list. Select the dropdown next to the English version to do so.</label>}
                                    {category?.CategoryName_MM !== null && <>{activeCatOptionMM === category.CategoryID ?
                                        <IoIosArrowUp onClick={() => setactiveCatOptionMM(null)} /> : <IoIosArrowDown onClick={() => ActiveCatOptionFuncMM(category.CategoryID)} />}</>}</label>
                                {activeCatOptionMM === category.CategoryID && <ul className='cat-options'>
                                    <li onClick={() => editActiveCatMM(category.CategoryID)}>Edit Category</li>
                                    {category?.CategoryType !== 'Master' && <li
                                        onClick={() => AddCategoryToMasterListExistingFunc(category?.CategoryName_EN, category?.CategoryName_MM, category?.CategoryID)}
                                    >Add Category to Master List</li>}
                                    <li
                                        onClick={() => AddNewSubcategoryToMasterFunc(category?.CategoryID, category?.CategoryName_MM)}
                                    >Add Subcategory</li>

                                    <li onClick={() => deleteCatFunction(category.CategoryID, 'cat')}>Delete Category</li>
                                </ul>}
                                {EditactiveCatMM === category.CategoryID && <label
                                    className='editLabel'
                                    style={{ border: EditactiveCatMM === category.CategoryID ? "2px solid #0036CD" : 'none' }}
                                >
                                    <label className='db-opt-close-cat'><IoMdClose onClick={() => closeCatEditBoxMM()} /></label>
                                    <label className='editinput'>

                                        {/* <span>{category?.CategoryName_MM}</span> */}
                                        <input
                                            type="text"
                                            name="CatBur"
                                            value={category.CategoryName_MM}
                                            onChange={(e) => onHandleCatChangeBur(e, index)}
                                        />
                                        <IoMdSave onClick={() => EditSaveCat('MM', category.CategoryID, category.CategoryName_MM)} />
                                    </label>
                                </label>}
                            </td>
                            <td style={{ textAlign: "center", position: "relative", padding: "0px" }}>
                                <ul className='db-sub'>
                                    {category?.SubCategories?.map((subCatEng, subCatIndex) =>
                                        <li
                                            key={subCatIndex}
                                            style={{
                                                backgroundColor: subCatEng?.CategoryName_MM === null ?
                                                    "#FFACCD85" : subCatEng?.CategoryName_EN === null ?
                                                        "#ececec" : "#fff"
                                            }}
                                        >
                                            {subCatEng?.CategoryName_EN !== null ? <label className='db-td'>{subCatEng?.CategoryName_EN}
                                                {SubCatIdEN === subCatEng.CategoryID ? <IoIosArrowUp
                                                    onClick={() => ActiveSubCatOptionFuncEN(null)}
                                                /> :
                                                    <IoIosArrowDown
                                                        onClick={() => ActiveSubCatOptionFuncEN(subCatEng.CategoryID)}
                                                    />}
                                            </label> : <label className='catSmall'>This subcategory is present only in English language. Translation can be added when the custom data has to be added to the master list. Select the dropdown next to the English version to do so.</label>}
                                            {SubCatIdEN === subCatEng.CategoryID && <ul className='cat-options'>
                                                <li onClick={() => editActiveSubCatFuEN(subCatEng.CategoryID)}>Edit Subcategory</li>
                                                {subCatEng?.CategoryType !== "Master" && <li
                                                    onClick={() => AddSubCategoryToexistingListFunc(subCatEng?.CategoryID, subCatEng?.ParentCategoryID, subCatEng?.CategoryName_EN, subCatEng?.CategoryName_MM)}
                                                >Add Subcategory to Master List</li>}
                                                <li onClick={() => deleteCatFunction(subCatEng.CategoryID, 'subCat')}>Delete Subcategory</li>
                                            </ul>}
                                            {EditactiveSubCatEN === subCatEng.CategoryID && <label
                                                className='editLabel'
                                                style={{ border: EditactiveSubCatEN === subCatEng.CategoryID ? "2px solid #0036CD" : 'none' }}
                                            >
                                                <label className='db-opt-close-subcat'><IoMdClose onClick={() => closeSubCatEditBoxEN()} /></label>
                                                <label className='editinput'>

                                                    {/* <span>{subCatEng?.CategoryName_EN}</span> */}
                                                    <input
                                                        type="text"
                                                        name="SubCatEn"
                                                        value={subCatEng.CategoryName_EN}
                                                        // onChange={(e) => setSubCatName(e.target.value)}
                                                        onChange={(e) => onHandleSubCatChangeEn(e, subCatIndex, index)}
                                                    />
                                                    <IoMdSave onClick={() => EditSaveSubCat('EN', subCatEng.CategoryID, subCatEng.CategoryName_EN)} />
                                                </label>
                                            </label>}
                                        </li>)}
                                </ul>

                            </td>
                            <td style={{ textAlign: "center", position: "relative", padding: "0px" }}>
                                <ul className='db-sub'>
                                    {category?.SubCategories?.map((subCatMM, subCatIndexMM) =>
                                        <li
                                            key={subCatIndexMM}
                                            style={{ backgroundColor: subCatMM?.CategoryName_EN === null ? "#FFACCD85" : subCatMM?.CategoryName_MM === null ? "#ececec" : "#fff" }}
                                        >
                                            {subCatMM?.CategoryName_MM !== null ? <label className='db-td'>{subCatMM?.CategoryName_MM}
                                                {SubCatIdMM === subCatMM.CategoryID ? <IoIosArrowUp
                                                    onClick={() => ActiveSubCatOptionFuncEN(null)}
                                                /> :
                                                    <IoIosArrowDown
                                                        onClick={() => ActiveSubCatOptionFuncMM(subCatMM.CategoryID)}
                                                    />}
                                            </label> : <label className='catSmall'>This subcategory is present only in English language. Translation can be added when the custom data has to be added to the master list. Select the dropdown next to the English version to do so.</label>}
                                            {SubCatIdMM === subCatMM.CategoryID && <ul className='cat-options'>
                                                <li onClick={() => editActiveSubCatFuMM(subCatMM.CategoryID)}>Edit Subcategory</li>
                                                {subCatMM?.CategoryType !== "Master" && <li
                                                    onClick={() => AddSubCategoryToexistingListFunc(subCatMM?.CategoryID, subCatMM?.ParentCategoryID, subCatMM?.CategoryName_EN, subCatMM?.CategoryName_MM)}
                                                >Add Subcategory to Master List</li>}
                                                <li onClick={() => deleteCatFunction(subCatMM.CategoryID, 'subCat')}>Delete Subcategory</li>
                                            </ul>}
                                            {EditactiveSubCatMM === subCatMM.CategoryID && <label
                                                className='editLabel'
                                                style={{ border: EditactiveSubCatMM === subCatMM.CategoryID ? "2px solid #0036CD" : 'none' }}
                                            >
                                                <label className='db-opt-close-subcat'><IoMdClose onClick={() => closeSubCatEditBoxMM()} /></label>
                                                <label className='editinput'>

                                                    {/* <span>{subCatMM?.CategoryName_MM}</span> */}
                                                    <input
                                                        type="text"
                                                        name="SubCatBur"
                                                        value={subCatMM.CategoryName_MM}
                                                        onChange={(e) => onHandleSubCatChangeBur(e, subCatIndexMM,index)}
                                                    />
                                                    <IoMdSave onClick={() => EditSaveSubCat('MM', subCatMM.CategoryID, subCatMM.CategoryName_MM)} />
                                                </label>
                                            </label>}
                                        </li>)}
                                </ul>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                <AddCategory />
                <AddSubcategoryToMaster
                    SelectedSubCatId={SelectedSubCatId}
                    popUp={SelectedSubCatModel}
                    setSelectedSubCatModel={setSelectedSubCatModel}
                    selectedCatname={selectedCatname}
                />
                <AddExistingCategoryToMaster
                    SelectedCatModel={SelectedCatModel}
                    setSelectedCatModel={setSelectedCatModel}
                    selectedCategoryDetails={selectedCategoryDetails}
                    setSelectedCategoryDetails={setSelectedCategoryDetails}
                />
                <AddSubCatToExistingMasterList
                    subCategoryDetails={subCategoryDetails}
                    setSubCategoryDetails={setSubCategoryDetails}
                    SubCategoryPopup={SubCategoryPopup}
                    setSubCategoryPopup={setSubCategoryPopup}
                />
                <Modal
                    className="modalStyle text-center"
                    show={DeleteShowCat}
                    onHide={() => setDeleteShowCat(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <IoMdCloseCircle className="modelClose" onClick={() => setDeleteShowCat(false)} />
                        <div className="mt-4 mb-3">
                            <div className="modelText">{LanguageOption === 'cat' ? "Are you sure you want to delete this Category?" : "Are you sure you want to delete this Subcategory?"}</div>
                            <div className='buttomModel'>
                                <button className='alertbuttonNo' onClick={() => deleteCatStatus('no')}>No</button>
                                <button className='alertbuttonYes' onClick={() => deleteCatStatus('yes')}>Yes</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>



            </div>
        </div>
    )
}

export default DatabaseTable