import React from 'react'
import Style from './forgotpassword.module.css';
import { useNavigate } from 'react-router-dom';


const ForgotpasswordSuccess = () => {
    let navigate = useNavigate();
    const setPassword = () => { 
        navigate('/')
      };

    return (
        <div className={Style.forgotWrapper}>
            <div className="row">
                <div className="col-md-12 mt-3">
                    <h3>Forgot Password</h3>
                    <p>Please check your email for the link to reset your password.</p>
                </div>
            </div>
            <div className='row'>  
                <div className='col-12'>
                    <button className='btn_primary w-100' onClick={setPassword} >Go back to login</button>
                </div>
            </div>


        </div>
    )
}

export default ForgotpasswordSuccess