import React, { useEffect, useMemo, useState } from 'react'
import { IoMdCloseCircle, IoMdDownload } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import ViewListing from '../popupComponents/viewListing';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetAllUsers, RemoveUserApi, UserViewListings } from '../../services/adminApis';
import { toast } from 'react-toastify';
import DateAndTime from '../DateAndTime/date_time';
import { Link } from 'react-router-dom';

const UserTable = ({
    searchValue,
    EnableJoined,
    EnableName,
    EnableEmail,
    EnableBussiness,
    EnablePhone,
    EnableLocation
}) => {

    let lowerValue = searchValue.toLowerCase()
    const [adminRole, setAdminRole] = useState(null)
    const [viewListings, setViewListings] = useState(false);
    const [removeUser, setRemoveUser] = useState(false);
    const [currentUserId, setCurretuserId] = useState('');
    const queryClient = useQueryClient()
    const { data: usersData, isError } = useQuery('allUser', GetAllUsers);
    const { mutate: viewListingsFunc, data: alldata, isLoading } = useMutation('viewlistings', UserViewListings, {
        retry: 0
    })
    const { mutate: deleteUser } = useMutation('deleteuser', RemoveUserApi, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('allUser');
            toast.success(data.message)
        },
    })


    const showViewListings = (userId) => {
        viewListingsFunc(userId)
        setViewListings(true)
    }
    const handleRemoveUser = (id) => {
        setRemoveUser(true)
        setCurretuserId(id)
    }
    const removeUserFunc = () => {
        deleteUser(currentUserId);

        setRemoveUser(false);
    }
    const getFilterArray = useMemo(() => {
        let filterdta = usersData?.filter((item) => {
            return EnableName && item.FullName?.toLowerCase().includes(lowerValue) ?
                item : EnableJoined && item.CreatedAt?.toLowerCase().includes(lowerValue) ?
                    item : EnableEmail && item.EmailAddress?.toLowerCase().includes(lowerValue) ?
                        item : EnableBussiness && item.BusinessName?.toLowerCase().includes(lowerValue) ?
                            item : EnableLocation && item.City?.includes(lowerValue) ?
                                item : EnableLocation && item.Township?.toLowerCase().includes(lowerValue) ?
                                    item : EnableLocation && item.Country?.toLowerCase().includes(lowerValue) ?
                                        item : EnablePhone && item.PhoneNumber?.toLowerCase().includes(lowerValue)
        })
        return filterdta
    }, [lowerValue, EnableName, EnableJoined, EnableEmail, EnableBussiness, EnableLocation, EnablePhone, usersData])


    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    //console.log('userDetails--', userDetails.role)
    //setAdminRole(userDetails);
    //console.log(adminRole?.role);

    return (
        <div className="table-fixed-column-outter">
            <div className="table-fixed-column-inner">
                <table className={'table table-striped table-bordered table-fixed-column userTableDate'}>
                    <thead>
                        <tr>
                            <th className='sticky-col'>T&C</th>
                            <th className='sticky-col2'>NAME OF USER</th>
                            {/* <th style={{ width: "300px" }}></th> */}
                            <th style={{ width: "300px" , display: EnableJoined ? 'table-cell' : "none" }}>JOINED ON</th>
                            <th style={{ width: "300px" , display: EnableEmail ? 'table-cell' : "none"  }}>EMAIL</th>
                            <th style={{ width: "300px" , display: EnableBussiness ? 'table-cell' : "none"  }}>NAME OF BUSINESS</th>
                            <th style={{ width: "300px" , display: EnablePhone ? 'table-cell' : "none" }}>PHONE NUMBER</th>
                            <th style={{ width: "300px" , display: EnableLocation ? 'table-cell' : "none"  }}>LOCATION</th>
                            <th style={{ width: "300px" }}>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "auto", maxHeight: "200px" }}>
                        {getFilterArray != undefined && getFilterArray.length > 0 ? getFilterArray.map((user, index) => <tr key={index}>
                            <td className='sticky-col'>
                                {user?.terms_url ? <Link to={user?.terms_url} download target='_blank'><IoMdDownload className="dowloadIcon" /></Link> :
                                    <Link><IoMdDownload className="dowloadIcondisabled" /></Link>}
                            </td>
                            <td className='sticky-col2'>{user?.FullName}</td>
                            {/* <td></td> */}
                            <td style={{display: EnableJoined ? 'table-cell' : "none" }}>
                                <DateAndTime DateTime={user?.CreatedAt} />
                            </td>
                            <td style={{display: EnableEmail ? 'table-cell' : "none" }}>{user?.EmailAddress}</td>
                            <td style={{display: EnableBussiness ? 'table-cell' : "none" }}>{user?.BusinessName}</td>
                            <td style={{display: EnablePhone ? 'table-cell' : "none" }}>{user?.PhoneNumber}</td>
                            <td style={{display: EnableLocation ? 'table-cell' : "none" }}>{user?.Township}, {user?.City}, {user?.Country}</td>
                            <td>
                                <button
                                    className="userManageBtn"
                                    onClick={() => showViewListings(user?.UserID)}
                                >View Listings</button>
                                {userDetails.role == "SuperAdmin" &&
                                    <button
                                        className="userManageBtn"
                                        onClick={() => handleRemoveUser(user?.UserID)}
                                    >Remove User</button>
                                }
                            </td>
                        </tr>
                        ) : <tr><td style={{ width: "100%" }}>No data found.</td></tr>}
                    </tbody>
                </table>
            </div>
            <Modal
                className="ViewmodalStyle text-center"
                show={viewListings}
                //onHide={() => setViewListings(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    {/* <IoMdCloseCircle className="modelClose" onClick={()=>SetLogout(false)}/> */}
                    <div className='row py-4' style={{ backgroundColor: alldata !== undefined && alldata.length > 0 ? "transparent" : "#fff" }}>
                        <div className='col-12'>
                            {alldata !== undefined && alldata.length > 0 ? <ViewListing title="123" ViewListingData={alldata} /> : <div>No listings</div>}
                            <span className="modelListClose" onClick={() => setViewListings(false)} ><IoMdCloseCircle style={{ fontSize: "34px", marginBottom: "3px" }} /> Close</span>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Modal
                className="modalStyle text-center"
                show={removeUser}
                onHide={() => setRemoveUser(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <IoMdCloseCircle className="modelClose" onClick={() => setRemoveUser(false)} />
                    <div className="mt-4 mb-3">
                        <div className="modelText">Are you sure you want to remove user?</div>
                        <div className='buttomModel'>
                            <button className='alertbuttonYes' onClick={removeUserFunc}>Ok</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserTable;