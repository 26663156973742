// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .activityLog_filterWrapper__1TLeD{
    margin-bottom: -120px;
 }
.activityLog_filterWrapper__1TLeD h2{
    color: #707070;
    font-family: 'interSemiBold';
    font-size: 24px;
    margin: 0;
    padding-top: 15px;
}
.activityLog_search__GdkzS {
    position: relative;
}
.activityLog_search__GdkzS input{
    border: 0;
    box-shadow: 0px 0px 8px 1px #e9e9e9;
    padding: 10px 10px 10px 40px;
}
.activityLog_search__GdkzS svg {
    position: absolute;
    top: 10px;
    font-size: 24px;
    left: 10px;
    color: #4A4A4A;
} 
.activityLog_datatblWrapper__3iq5t header{
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/tools/activityLog/activityLog.module.css"],"names":[],"mappings":"CAAC;IACG,qBAAqB;CACxB;AACD;IACI,cAAc;IACd,4BAA4B;IAC5B,eAAe;IACf,SAAS;IACT,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,SAAS;IACT,mCAAmC;IACnC,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,UAAU;IACV,cAAc;AAClB;AACA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[" .filterWrapper{\r\n    margin-bottom: -120px;\r\n }\r\n.filterWrapper h2{\r\n    color: #707070;\r\n    font-family: 'interSemiBold';\r\n    font-size: 24px;\r\n    margin: 0;\r\n    padding-top: 15px;\r\n}\r\n.search {\r\n    position: relative;\r\n}\r\n.search input{\r\n    border: 0;\r\n    box-shadow: 0px 0px 8px 1px #e9e9e9;\r\n    padding: 10px 10px 10px 40px;\r\n}\r\n.search svg {\r\n    position: absolute;\r\n    top: 10px;\r\n    font-size: 24px;\r\n    left: 10px;\r\n    color: #4A4A4A;\r\n} \r\n.datatblWrapper header{\r\n    background-color: transparent;\r\n    box-shadow: none;\r\n    margin-bottom: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWrapper": `activityLog_filterWrapper__1TLeD`,
	"search": `activityLog_search__GdkzS`,
	"datatblWrapper": `activityLog_datatblWrapper__3iq5t`
};
export default ___CSS_LOADER_EXPORT___;
