import React, { useEffect, useMemo, useState } from 'react'
import Style from './activityLog.module.css';
import { Form, FormControl, Button, InputGroup } from 'react-bootstrap';
import { IoMdSearch } from "react-icons/io";
// import Pagination from 'react-bootstrap/Pagination';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { ActivityLogApi } from '../../../services/adminApis';
import LoaderComponent from '../../../components/loader/loader';
import DateAndTime from '../../../components/DateAndTime/date_time';

const ActivityLog = () => {
    const columns = [
        {
            name: 'NAME OF USER',
            sortable: false,
            selector: row => row?.FullName,
        },
        {
            name: 'ACTIONS',
            sortable: false,
            selector: row => row?.Description,
        },
        {
            name: 'DATE & TIME',
            sortable: false,
            selector: row => <DateAndTime DateTime={row?.Timestamp} />,
        },
    ];


    const { data: activityLogData, isLoading } = useQuery('activity_log', ActivityLogApi);

    const [filterInput, setFilterInput] = useState(' ');
    const filterInputLog = (e) => {
        let value = e.target.value
        let lowercaseValue = value.toLowerCase();
        setFilterInput(lowercaseValue)
    }
    const filterArray = () => {
        let filteredData = activityLogData?.filter((item) => {
            const descriptionMatch = item.Description?.toLowerCase().includes(filterInput);
            const actionMatch = item.Action?.toLowerCase().includes(filterInput);
            // Convert Timestamp to a Date object
            const timestampDate = new Date(item?.Timestamp);
    
            // Format the date as a string with date and time
            const formattedTimestamp = timestampDate.toLocaleString('en-GB');
            let removeComma = formattedTimestamp.split(',');
            let reArrangeDate = removeComma[0]+' '+removeComma[1]

            // Check if either the description, action, or formatted timestamp includes the filter input
            return descriptionMatch || actionMatch || reArrangeDate.toLowerCase().includes(filterInput);
        });
    
        return filteredData;
    }
    let latestArray = filterArray();
    useEffect(() => {
        filterArray();
    }, [filterInput])
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className={Style.filterWrapper}>
                        <h2>Activity Log</h2>
                    </div>
                </div>
                <div className={Style.datatblWrapper + ' ' + 'col-md-12 dataTable'}>
                    {isLoading === false ? <DataTable
                        // sortactive={false}
                        columns={columns}
                        data={latestArray}
                        sort={false}
                        pagination
                        subHeader
                        subHeaderComponent={
                            <div className="col-md-4">
                                <Form>
                                    <div className={Style.search}>
                                        <FormControl
                                            placeholder="Search for any username, action or date"
                                            onChange={(e) => filterInputLog(e)}
                                        />
                                        <IoMdSearch />
                                    </div>
                                </Form>
                            </div>
                        }
                    /> : <LoaderComponent />}
                </div>
            </div>
        </div>
    )
}

export default ActivityLog