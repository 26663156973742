import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import { useMutation, useQueryClient } from 'react-query';
import { AddCategoryToMasterExistingListApi} from '../../services/adminApis';
import { toast } from 'react-toastify';

const AddCategoryToMaster = ({
    SelectedCatModel,
    setSelectedCatModel,
    selectedCategoryDetails,
    setSelectedCategoryDetails
}) => {
    let queryClient = useQueryClient();
        const {mutate:addcatMutation} = useMutation('add_subCatToMaster',AddCategoryToMasterExistingListApi,{
            onSuccess:(data)=>{
               toast.success(data.message);
               queryClient.invalidateQueries('getAllCategories')
            }
        });
    const addToMaterList =()=>{
        let obj ={
            "categoryName_EN": selectedCategoryDetails.categoryName_EN,
            "categoryName_MM": selectedCategoryDetails.categoryName_MM,
            "categoryID": selectedCategoryDetails.categoryID
        }
        addcatMutation(obj);
        setSelectedCatModel(false)
        setSelectedCategoryDetails({
            "categoryName_EN": '',
            "categoryName_MM": '',
            "categoryID":''
        })
    }
    return (
        <Modal
            className="modalStyle text-center subcategorymodel"
            show={SelectedCatModel}
            onHide={() => setSelectedCatModel(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <IoMdCloseCircle className="modelClose"
                    onClick={() => setSelectedCatModel(false)}
                />
                <div className="mt-4 mb-3">
                    <h4 className='db-pop-title' style={{ textAlign: "left" }}>Add Custom Category to Master List</h4>
                    <div className='row mt-3' style={{ textAlign: "left" }}>
                        <h4 className="catTitle" style={{ fontSize: 16 }}>Category</h4>
                        <div className='col-6 catInput'>
                            <label>Label in English</label>
                            <input
                                type="text"
                                value={selectedCategoryDetails.categoryName_EN}
                                onChange={(e) => setSelectedCategoryDetails({
                                    ...selectedCategoryDetails,
                                    categoryName_EN: e.target.value
                                })}
                            />
                        </div>
                        <div className='col-6 catInput'>
                            <label>Label in Burmese</label>
                            <input
                                type="text"
                                value={selectedCategoryDetails.categoryName_MM}
                                onChange={(e) => setSelectedCategoryDetails({
                                    ...selectedCategoryDetails,
                                    categoryName_MM: e.target.value
                                })}
                            />
                        </div>
                        <div className='row mt-4 text-center px-0'>
                            <div className='col-12 px-0'
                                style={{ textAlign: "right" }}
                            >
                                <button
                                    className='addNewSubcat'
                                    style={{ background: selectedCategoryDetails.categoryName_EN!==''&&selectedCategoryDetails.categoryName_MM!==''?"#5BA15B":"#A5A5A5", color: "#fff", padding: "8px 40px" }}
                                    onClick={addToMaterList}
                                    disabled={selectedCategoryDetails.categoryName_EN!==''&&selectedCategoryDetails.categoryName_MM!==''?false:true}

                                >Add to Master List <FaArrowRight style={{ fontSize: "16px" }} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddCategoryToMaster;