import React from 'react'
import Style from '../popupComponents/popupcomponentStyle.module.css';
import Unpublished from '../../assets/images/unpublished_white.png';
import Draft from '../../assets/images/draft_white.png';
import Published from '../../assets/images/published_white.png';

const ListingStatus = ({listingStatus}) => {
  return (
    <div 
       className={Style.topLabelImg}
       style={{
           backgroundColor:listingStatus==="Published"?
           "#66a762":listingStatus==="Unpublished"?
           "#A5A5A5":"#f2bd38"
       }}
    >
       {listingStatus==="Published"&&<label>{listingStatus}<img src={Published} alt="aa" /></label>}
       {listingStatus==="Unpublished"&&<label>{listingStatus}<img src={Unpublished} alt="aa" /></label>}
       {listingStatus==="Draft"&&<label>{listingStatus}<img src={Draft} alt="aa" /></label>}
    </div>
  )
}

export default ListingStatus