import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import {IoMdCloseCircle } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import { useMutation, useQueryClient } from 'react-query';
import { AddSubcategoryToMasterApi } from '../../services/adminApis';
import { toast } from 'react-toastify';

const AddSubcategoryToMaster = ({
    SelectedSubCatId,
    setSelectedSubCatModel, 
    popUp,
    selectedCatname
}) => {
    const [selectedCateDetails,setSelectedCatDetails] = useState({
        "subCategoryName_EN": '',
        "subCategoryName_MM": ''
      })
    let queryClient = useQueryClient();
    const {mutate:addSubcatMutation} = useMutation('add_subCatToMaster',AddSubcategoryToMasterApi,{
        onSuccess:(data)=>{
           toast.success(data.message);
           queryClient.invalidateQueries('getAllCategories')
        }
    });
const addToMaterList =()=>{
    let obj ={
        "subCategoryName_EN": selectedCateDetails.subCategoryName_EN,
        "subCategoryName_MM": selectedCateDetails.subCategoryName_MM,
        "parentCategoryID": SelectedSubCatId
    }
    addSubcatMutation(obj);
    setSelectedSubCatModel(false)
    setSelectedCatDetails({
        "subCategoryName_EN": '',
        "subCategoryName_MM": ''
    })
}
const closePopup = ()=>{
    setSelectedSubCatModel(false)
    setSelectedCatDetails({
        "subCategoryName_EN": '',
        "subCategoryName_MM": ''
    })
}
    return (
        <Modal
            className="modalStyle text-center subcategorymodel"
            show={popUp}
            onHide={() => closePopup()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <IoMdCloseCircle className="modelClose"  onClick={() => closePopup()}/>
                <div className="mt-4 mb-3">
                    <h4 className='db-pop-title' style={{ textAlign: "left" }}>Add New Subcategory to Master List</h4>
                    <div className='row mt-3' style={{ textAlign: "left" }}>
                        <h4 className="catTitle" style={{ fontSize: 16 }}>Subcategory</h4>
                        <div className='col-6 catInput'>
                            <label>Label in English</label>
                            <input
                                type="text"
                            value={selectedCateDetails.subCategoryName_EN}
                            onChange={(e) => setSelectedCatDetails({
                              ...selectedCateDetails,
                                subCategoryName_EN:e.target.value
                            })}
                            />
                        </div>
                        <div className='col-6 catInput'>
                            {<label>Label in Burmese</label>}
                            <input
                                type="text"
                                value={selectedCateDetails.subCategoryName_MM}
                                onChange={(e) => setSelectedCatDetails({
                                    ...selectedCateDetails,
                                      subCategoryName_MM:e.target.value
                                  })}
                            />
                        </div>
                        <div className='row mt-4 text-center px-0'>
                            <div className='col-12 px-0'
                              style={{textAlign:"right"}}
                            >
                                <button
                                    className='addNewSubcat'
                                    style={{ background: selectedCateDetails.subCategoryName_EN!==''&&selectedCateDetails.subCategoryName_MM!==''?"#5BA15B":"#A5A5A5", color: "#fff", padding: "8px 40px" }}
                                    onClick={addToMaterList}
                                    disabled={selectedCateDetails.subCategoryName_EN!==''&&selectedCateDetails.subCategoryName_MM!==''?false:true}
                                    
                               >Add to Master List under {selectedCatname} <FaArrowRight style={{ fontSize: "16px" }} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddSubcategoryToMaster