import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SellerListings from './sellerListings';
import BuyerListings from './buyerListings';
import style from './userListings.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveUserListingTab } from '../../../redux/Actions';
import { useMutation } from 'react-query';
import { BuyerListingApi, SellerListingApi } from '../../../services/adminApis';
import LoaderComponent from '../../../components/loader/loader';


const UserListings = () => {
  const dispatch = useDispatch();
  const UserTabDispatch = (key) => {
    let tabKey = key.target.dataset.rrUiEventKey;
    dispatch(ActiveUserListingTab(tabKey))
  }
  const activeTab = useSelector((state) => state.activeTabUserListing);
  const { mutate: getSellerListing, data: sellerListingData, isLoading: sellerLoading } = useMutation('sellerListing', SellerListingApi);
  const { mutate: getBuyerListing, data: BuyerListingData, isLoading: BuyerLoading } = useMutation('BuyerListing', BuyerListingApi);
  useEffect(() => {
    if (activeTab == 'sl') {
      getSellerListing()
    }
    if (activeTab == 'bl') {
      getBuyerListing()
    }
  }, [activeTab])
 
  return (
    <div>
      <div className="row notificationTabWrapper userListingsWrapper">
        <div className={style.filterWrapper + ' ' + 'col-md-12 mb-3'}><h2>User Listings</h2></div>
        <div className="col-md-12 userlistingTab">
          <Tabs defaultActiveKey={activeTab} onClick={(key) => UserTabDispatch(key)}>
            <Tab eventKey="sl" title="Seller Listings">
              {sellerLoading===false?
                <>
                  {sellerListingData!==undefined&&sellerListingData.length>0?<SellerListings sellerData={sellerListingData} />:<div>No data found.</div>}
                </>:<LoaderComponent/>
              }
            </Tab>
            <Tab eventKey="bl" title="Buyer Listings">
            {BuyerLoading===false?
                <>
                  {BuyerListingData!==undefined&&BuyerListingData.length>0?<BuyerListings BuyerData={BuyerListingData} />:<div>No data found.</div>}
                </>:<LoaderComponent/>
              }
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default UserListings