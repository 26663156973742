import React, { useEffect, useState } from 'react'
import style from './users.module.css'
import { IoMdSearch } from "react-icons/io";
import UserTable from '../../components/table_components/user_table';
import { IoIosArrowDown } from "react-icons/io";
import { useQuery } from 'react-query';
import { GetAllUsers } from '../../services/adminApis';
import LoaderComponent from '../../components/loader/loader';
import { Modal } from 'react-bootstrap';

const Users = () => {
  const [ColumnOptions, setColumnOptions] = useState(false);
  const [search, setSearch] = useState('');
  const [EnableJoined, setEnableJoined] = useState(true);
  const [EnableName, setEnableName] = useState(true);
  const [EnableEmail, setEnableEmail] = useState(true);
  const [EnableBussiness, setEnableBussiness] = useState(true);
  const [EnablePhone, setEnablePhone] = useState(true);
  const [EnableLocation, setEnableLocation] = useState(true);
  const [EnableSelectAll, setEnableSelectAll] = useState(true);
  const [removeUser, setRemoveUser] = useState(false);

  const handleCheck =(e)=>{
      let value = e.target.value;
      //console.log("value-------->",value);
      if(value === 'join'){
        if(e.target.checked === true){
          setEnableJoined(true)
        }
        else{
          setEnableJoined(false)
        }
      }
      if(value === 'name'){
        if(e.target.checked === true){
          setEnableName(true)
        }
        else{
          setEnableName(false)
        }
      }
      if(value === 'email'){
        if(e.target.checked === true){
          setEnableEmail(true)
        }
        else{
          setEnableEmail(false)
        }
      }
      if(value === 'bussiness'){
        if(e.target.checked === true){
          setEnableBussiness(true)
        }
        else{
          setEnableBussiness(false)
        }
      }
      if(value === 'phone'){
        if(e.target.checked === true){
          setEnablePhone(true)
        }
        else{
          setEnablePhone(false)
        }
      }
      if(value === 'location'){
        if(e.target.checked === true){
          setEnableLocation(true)
        }
        else{
          setEnableLocation(false)
        }
      }
      if(value === 'selectAll'){
        if(e.target.checked === true){
          setEnableJoined(true)
          setEnableName(true)
          setEnableEmail(true)
          setEnableBussiness(true)
          setEnablePhone(true)
          setEnableLocation(true)
          setEnableSelectAll(true)
        }
        else{
          setEnableJoined(false)
          setEnableName(true)
          setEnableEmail(false)
          setEnableBussiness(false)
          setEnablePhone(false)
          setEnableLocation(false)
          setEnableSelectAll(false)
        }
      }
  }
  useEffect(() => {
    document.body.addEventListener('click', () => {
      setColumnOptions(false)
    });

  }, [])
  return (
    <div className={style.userWrapper}>
      <div className='row'>
        <div className='col-6'>
          <div className={style.userSearch}>
            <span><IoMdSearch /></span>
            <input
              type="text"
              placeholder='Search for any data...'
              value={search}
              onChange={(e) => {
                let value = e.target.value;
                setSearch(value)
              }
              }
            />
          </div>
        </div>
        <div className='col-6'>
          <div className={style.userSelect}>
            <label
              onClick={(e) => {
                e.stopPropagation()
                setColumnOptions(!ColumnOptions)
              }
              }
            >Column Options <IoIosArrowDown style={{}} /></label>
            {ColumnOptions && <ul  onClick={(e) => {
                e.stopPropagation()
                  }
              }>
              <li><input type="checkbox" id="join" value="join" onChange={(e)=>handleCheck(e)} checked={EnableJoined}/><label htmlFor="join">Joined on</label></li>
              {/* <li><input type="checkbox" id="name" value="name" onChange={(e)=>handleCheck(e)} checked={EnableName}/><label htmlFor='name'>Name of user</label></li> */}
              <li><input type="checkbox" id="email" value="email" onChange={(e)=>handleCheck(e)} checked={EnableEmail}/><label htmlFor='email'>Email</label></li>
              <li><input type="checkbox" id="bussiness" value="bussiness" onChange={(e)=>handleCheck(e)} checked={EnableBussiness}/><label htmlFor='bussiness'>Name of business</label></li>
              <li><input type="checkbox" id="phone" value="phone" onChange={(e)=>handleCheck(e)} checked={EnablePhone}/><label htmlFor='phone'>Phone Number</label></li>
              <li><input type="checkbox" id="location" value="location" onChange={(e)=>handleCheck(e)} checked={EnableLocation}/><label htmlFor='location'>Location</label></li>
              <li><input type="checkbox" id="selectAll" value="selectAll" onChange={(e)=>handleCheck(e)} checked={EnableSelectAll}/><label htmlFor='selectAll'>Select All</label></li>
            </ul>}
          </div>
        </div>
      </div>
      <div className='row px-3 pt-4'>
         
        <UserTable 
           searchValue={search} 
           EnableJoined ={EnableJoined}
           EnableName={EnableName}
           EnableEmail={EnableEmail}
           EnableBussiness={EnableBussiness}
           EnablePhone={EnablePhone}
           EnableLocation={EnableLocation}
          /> 
      </div>
    </div>
  )
}

export default Users;