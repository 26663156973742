import React, { useMemo } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Close from '../../assets/images/close_white.png';
import style from '../../pages/tools/userListings/userListings.module.css';
import { Link } from 'react-router-dom';
import DateAndTime from '../DateAndTime/date_time';
import { useMutation } from 'react-query';
import { SingleListingApi } from '../../services/adminApis';
import ListingStatus from '../listingStatus/listingStatus';
import NoImage from '../../assets/images/no_image.jpg';

const BuyerTableView = ({ BuyerDataset, searchInput }) => {
    let InputValLower = searchInput.toLowerCase();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { mutate: singleListingFunc, data: singleListingsData } = useMutation('singlelisting', SingleListingApi)
    const getSpecificListing = (ListingId) => {
        setShow(true);
        singleListingFunc(ListingId)
    }
    const getFilterArray = useMemo(() => {
        let filterdta = BuyerDataset?.filter((item) => {
            const timestampDate = new Date(item?.PostedOn);
            // Format the date as a string with date and time
            const formattedTimestamp = timestampDate.toLocaleString('en-GB');
            let removeComma = formattedTimestamp.split(',');
            let reArrangeDate = removeComma[0]+' '+removeComma[1];
            return   item.CategoryName_EN?.toLowerCase().includes(InputValLower) ||
            item.CategoryName_MM?.toLowerCase().includes(InputValLower) ||
            item.City?.toLowerCase().includes(InputValLower) ||
            item.DeliveryStatus?.toLowerCase().includes(InputValLower) ||
            item.Description?.toLowerCase().includes(InputValLower) ||
            item.ListingStatus?.toLowerCase().includes(InputValLower) ||
            item.PostedBy?.toLowerCase().includes(InputValLower) ||
            item.PostedOn?.toLowerCase().includes(InputValLower) ||
            item.PriceMax?.toLowerCase().includes(InputValLower) ||
            item.PriceMin?.toLowerCase().includes(InputValLower) ||
            item.Quantity?.toLowerCase().includes(InputValLower) ||
            item.SiteInspectionStatus?.toLowerCase().includes(InputValLower) ||
            // item.Subcategory?.toLowerCase().includes(InputValLower) ||
            // item.Category?.toLowerCase().includes(InputValLower) ||
            item.TownShip?.toLowerCase().includes(InputValLower) ||
            item.Unit?.toLowerCase().includes(InputValLower) ||
            item.ListingID?.toString().includes(InputValLower)||
            reArrangeDate.toLowerCase().includes(InputValLower)||
            item.SubcategoryName_EN?.toLowerCase().includes(InputValLower) ||
            item.SubcategoryName_MM?.toLowerCase().includes(InputValLower) 
        })
        return filterdta
    }, [InputValLower,BuyerDataset])
    return (
        <div className="listViewWrapper my-4">
            <table className="table table-striped table-bordered dashTable slTBL">
                <thead style={{display:"block"}}>
                    <tr>
                        <th style={{ width: "10%" }}>LISTING NO</th>
                        <th style={{ width: "10%" }}>POSTED ON</th>
                        <th style={{ width: "15%" }}>POSTED BY</th>
                        <th style={{ width: "15%" }}>CATEGORY</th>
                        <th style={{ width: "10%" }}>SUBCATEGORY</th>
                        <th style={{ width: "10%" }}>QUANTITY</th>
                        <th style={{ width: "15%" }}>PRICE</th>
                        <th style={{ width: "15%" }}></th>
                    </tr>
                </thead>
                <tbody className='tableScroll'>
                    {getFilterArray!==undefined&&getFilterArray.length>0?getFilterArray?.map((buyer, index) => <tr key={index}>
                        <td style={{ width: "10%" }}>{buyer?.ListingID}</td>
                        <td style={{ width: "10%" }}><DateAndTime DateTime={buyer?.PostedOn} /></td>
                        <td style={{ width: "15%" }}>{buyer?.PostedBy}</td>
                        {/* <td style={{ width: "15%" }}>{buyer?.Category}</td>
                        <td style={{ width: "10%" }}>{buyer?.Subcategory}</td> */}
<td style={{ width: "15%" }}>
                             {buyer?.CategoryName_EN!==''&&buyer?.CategoryName_EN!==null?buyer?.CategoryName_EN:buyer?.CategoryName_MM}
                       </td>
                        <td style={{ width: "10%" }}>
                        {buyer?.SubcategoryName_EN!==''&&buyer?.SubcategoryName_EN!==null?buyer?.SubcategoryName_EN:buyer?.SubcategoryName_MM}
                        </td>
                        <td style={{ width: "10%" }}>{buyer?.Quantity} {buyer?.Unit}</td>
                        <td style={{ width: "15%" }}>Ks.{buyer?.PriceMin}/{buyer?.Unit} - Ks.{buyer?.PriceMax}/{buyer?.Unit}</td>
                        <td style={{ width: "15%" }} className={style.actions}>
                            <Link onClick={() => getSpecificListing(buyer?.ListingID)}>Detailed View</Link>
                        </td>
                    </tr>):<div>No data found.</div>}
                </tbody>
            </table>
            <Modal show={show} centered className="viewListing">
                <Modal.Body className='text-center' style={{ backgroundColor: singleListingsData?.ListingType === "Seller"?"#E0EECE":"#FDF3DB"}}>
                <div style={{
                                left: "3px",
                                top: "3px",
                                position: "absolute",
                                width: "100%"
                            }}>
                    <ListingStatus
                        listingStatus={singleListingsData?.ListingStatus}
                    />
                    </div>
                    <div className='row border-bottom py-2 mt-3'>
                        <div className='col-6'><h5>{singleListingsData?.ListingID}</h5></div>
                        <div className='col-6'><h5><DateAndTime DateTime={singleListingsData?.CreatedAt}/></h5></div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-6'><h5>{singleListingsData?.FullName}</h5></div>
                        <div className='col-6'><h5>{singleListingsData?.ImageURLs!==''&&singleListingsData?.ImageURLs!==null?`${singleListingsData?.ImageURLs.split(',').length} image${singleListingsData?.ImageURLs.split(',').length>1?"'s":''}`:''}</h5></div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-md-12'>
                            <Carousel className='listingSlider'
                            interval={null}
                            indicators={false}
                            controls={singleListingsData?.ImageURLs!==''&&singleListingsData?.ImageURLs!==null&&singleListingsData?.ImageURLs.split(',').length>1?true:false}
                            >
                                 {singleListingsData?.ImageURLs!==''&&singleListingsData?.ImageURLs!==null?singleListingsData?.ImageURLs.split(',').map((item,index)=><Carousel.Item interval={1000} key={index}>
                                        <img src={item} alt="aa" />
                                    </Carousel.Item>):
                                    <Carousel.Item>
                                        <img src={NoImage} alt="aa" />
                                    </Carousel.Item>
                                    }
                            </Carousel>

                        </div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-6'><h5>{singleListingsData?.SubCategoryName}</h5></div>
                        <div className='col-6'><h5>{singleListingsData?.CategoryName}</h5></div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-6'><h5>Ks.{singleListingsData?.PriceMax}/{singleListingsData?.Unit}</h5></div>
                        <div className='col-6'><h5>{singleListingsData?.Quantity} {singleListingsData?.Unit}</h5></div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-md-12'>
                            <p>{singleListingsData?.Description}</p>
                        </div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-6'><h5>{singleListingsData?.Township}</h5></div>
                        <div className='col-6'><h5>{singleListingsData?.City}</h5></div>
                    </div>
                    <div className='row border-bottom py-2'>
                        <div className='col-6'><h5>{singleListingsData?.DeliveryStatus}</h5></div>
                        <div className='col-6'><h5>{singleListingsData?.SiteInspectionStatus}</h5></div>
                    </div>
                </Modal.Body>
                <Link className='modalClose' onClick={handleClose}><img src={Close} alt="aa" /> Close</Link>
            </Modal>
        </div>
    )
}

export default BuyerTableView