import React, { useEffect } from 'react'
import ExcelJS, { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';
const GenerateExcelfile = ({
  setGenerateExcel,
  MonthList,
  NewUserCountList,
  TotalNumberOfusers,
  UserAggregate,
  SellerListingsCount,
  BuyerListingsCount,
  MessagesCount,
  ConnectionCount,
  timeStamp,
  selectedYear,
  currentMonthName
}) => {
  const exportExcel = () => {
    let MonthUpdatedList = MonthList.filter((item)=>{
       if(item!=="Total"&&item!=="Avg"){
        return item
       }
    })
    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet('my sheet');
    workSheet.properties.defaultRowHeight = 30;

    workSheet.columns = [
      {
        header: "METRICS",
        key: "metric",
        width: "30"
      }
    ]
    const headers = []
    try {
      if (Array.isArray(MonthList)) {
        MonthList.forEach((month) => {
          const header = {
            header: `${month}`,
            key: month,
            width:10,
          };
          headers.push(header)
        })
      }
      workSheet.columns = [...workSheet.columns, ...headers];
      let NewUserCountListValues = [{ 'title': 'Number of New Users' }, ...NewUserCountList]
      NewUserCountListValues = NewUserCountListValues && NewUserCountListValues.flatMap(Object.values);
      let UserAggregateValues = UserAggregate;
      let TotalNumberOfusersValues = [{ 'title': 'Total Number of Users' }, ...TotalNumberOfusers, UserAggregateValues]
      TotalNumberOfusersValues = TotalNumberOfusersValues && TotalNumberOfusersValues.flatMap(Object.values);

      let SellerListingsCountValues = [{ 'title': 'Number of New Seller Listings' }, ...SellerListingsCount]
      SellerListingsCountValues = SellerListingsCountValues && SellerListingsCountValues.flatMap(Object.values);

      let BuyerListingsCountValues = [{ 'title': 'Number of New Buyer Listings' }, ...BuyerListingsCount]
      BuyerListingsCountValues = BuyerListingsCountValues && BuyerListingsCountValues.flatMap(Object.values);

      let MessagesCountValues = [{ 'title': 'Number of Messages Exchanged' }, ...MessagesCount]
      MessagesCountValues = MessagesCountValues && MessagesCountValues.flatMap(Object.values);

      let ConnectionCountValues = [{ 'title': 'Number of Connections Made' }, ...ConnectionCount]
      ConnectionCountValues = ConnectionCountValues && ConnectionCountValues.flatMap(Object.values);

      workSheet.addRow(NewUserCountListValues);
      workSheet.addRow(TotalNumberOfusersValues);
      workSheet.addRow(SellerListingsCountValues);
      workSheet.addRow(BuyerListingsCountValues);
      workSheet.addRow(MessagesCountValues);
      workSheet.addRow(ConnectionCountValues);
      workSheet.eachRow({ includeEmpty: false }, (row) => {
        row.eachCell((cell) => {
          cell.alignment = { horizontal: 'left' };
        });
      });
      workSheet.eachRow({ includeEmpty: false }, (row) => {
        row.eachCell((cell) => {
          cell.border = { 
            top: {style:'thin'},
            bottom:{style:'thin'}
          };
        });
      });
      // Add to give custom bold to last two columns
      //workSheet.lastColumn.font={ bold: true };
      workSheet.getRow(1).getCell("Total").font={ bold: true }
      workSheet.getRow(1).getCell("Avg").font={ bold: true }
      //workSheet.getRow(1).column("Total").font={ bold: true };

      workBook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(
          blob,
          `wtw_${MonthUpdatedList[0]}${selectedYear}_${MonthUpdatedList[MonthUpdatedList.length - 1]}${selectedYear}_${timeStamp()}.xlsx`
        );
      });
      setGenerateExcel(false)
    }
    catch (error) {
      toast.error("Error generating Excel file.");
    }
  }
  useEffect(() => {
    exportExcel()
  }, [setGenerateExcel])
  return (
    <div></div>
  )
}

export default GenerateExcelfile