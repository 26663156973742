import React from 'react'

const DateAndTime = ({DateTime}) => {
    return (
        <>
            {new Date(DateTime).toLocaleDateString('en-GB', { day: "numeric", month: "numeric", year: "numeric" })} &nbsp;
            {new Date(DateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',hour12: false })}
        </>
    )
}

export default DateAndTime