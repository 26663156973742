// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageAdmin_manageAdmin__WQLXr h2{
    color: #707070;
    font-weight: bold;
    font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tools/manageAdmin/manageAdmin.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".manageAdmin h2{\r\n    color: #707070;\r\n    font-weight: bold;\r\n    font-size: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageAdmin": `manageAdmin_manageAdmin__WQLXr`
};
export default ___CSS_LOADER_EXPORT___;
