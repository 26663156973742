import { useState, useRef, useEffect } from 'react';
import style from '../../pages/notifications/notification.module.css';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { IoMdCloseCircle } from "react-icons/io";
import user from '../../assets/images/user_profile_default.png';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { DismisReportApi, GetSpecificUserApi, RemoveUserApi, ReportSeenApi, ReportedUserApi } from '../../services/adminApis';
import LoaderComponent from '../loader/loader';
import { toast } from 'react-toastify';
import { TokenExpiry } from '../../pages/utils/tokenExpiry';
import DateAndTime from '../DateAndTime/date_time';

const ReportedUsers = () => {
    //Notification fix
    const queryClient = useQueryClient();
    const [adminRole, setAdminRole] = useState(null)
    const [removeUser, setRemoveUser] = useState(false);
    const [RemoveReport, setRemoveReport] = useState(false)
    const [userId, setUserId] = useState();
    const [ReportId, setReportId] = useState();
    const [nameOfUser, setNameofUser] = useState('')
    const handleClose = () => {
        setRemoveUser(false);
        setRemoveReport(false);
    }
    const ref = useRef(null);
    const activeTab = useSelector((state) => state.activetab);
    //Notification fix
    const { mutate: activeCurrentTab, data: reportedUserData, isLoading } = useMutation('reported_user', ReportedUserApi
        , {
            onSuccess: (data) => {
                queryClient.invalidateQueries('NotificationSeenStatus')
            }
        });
    //const {mutate:userDetailsFuc} = useMutation()
    const { mutate: reportseenFunc } = useMutation('report_seen', ReportSeenApi);
    const { mutate: removeUserFunc } = useMutation('remove_user', RemoveUserApi, {
        onSuccess: (data) => {
            activeCurrentTab();
            toast.success(data.message)
        }
    });
    const { mutate: dismissReport } = useMutation('dismiss_report', DismisReportApi, {
        onSuccess: (data) => {
            activeCurrentTab();
            let successMessage = data.message;
            toast.success(successMessage)
        }
    })
    const { mutate: getUserDetail, data: userDetails } = useMutation('getUserDetails', GetSpecificUserApi);
    const DismisReportFunc = (ReportId, name) => {
        setRemoveReport(true)
        setReportId(ReportId)
        setNameofUser(name)

    }
    const handleDismiss = (status) => {
        if (status === 'yes') {
            setRemoveReport(false)
            dismissReport(ReportId)
        }
        else {
            setRemoveReport(false)
        }
    }
    const RemoveUser = (id, name) => {
        setRemoveUser(true)
        setNameofUser(name)
        setUserId(id)
    }
    const handleRemoveUse = (status) => {
        if (status === 'yes') {
            removeUserFunc(userId)
            setRemoveUser(false);
        }
        else {
            setRemoveUser(false);
        }
    }
    // const notficationDispatch = (obj) => {
    //     dispatch(obj)
    // }
    // const toggleVisibility = (id) => {
    //     setIsVisible(id);
    //     getUserDetail(id);
    // };
    const getUserIdFunc = (index, specificuserId) => {
        getUserDetail(specificuserId);
        setIsVisible(index);
    }
    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
        setAdminRole(userDetails);
        if (activeTab === 'ru') {
            activeCurrentTab({})

            return () => {
                if (!TokenExpiry(localStorage.getItem('adminTokenExpiry'))) {
                    reportseenFunc({
                        "reportType": "User"
                    })
                    //notficationDispatch(ActiveReportUserNotification(false))
                }
            }
        }
    }, [activeTab])

    const [isVisible, setIsVisible] = useState(null);
    return (
        <div className='notificationWraper reportedU'>
            <div className="row">
                {isLoading === false ? <div className="col-md-12">
                    {reportedUserData !== undefined && reportedUserData.length > 0 ? <table className="table table-striped table-bordered dashTable tlTable">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>REPORTED USER</th>
                                <th style={{ width: "15%" }}>REPORTED ON</th>
                                <th style={{ width: "15%" }}>REPORTED BY</th>
                                <th style={{ width: "20%" }}>REASON</th>
                                <th style={{ width: "25%" }}>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportedUserData?.map((Reporteduser, index) => <tr key={index}>
                                <td style={{ position: "relative", width: "25%" }} className={style.actions}>{Reporteduser?.Seen === 0 && <span className='notificationiconReportTable'></span>}{Reporteduser?.['Reported User']}
                                    <Button onClick={(e) => getUserIdFunc(index, Reporteduser.ReportedUserID)} className='btn_viewUser'>View User</Button>

                                    {isVisible === index &&
                                        <div className={style.userDetails} ref={ref} >
                                            <IoMdCloseCircle className="modelClose" onClick={() => setIsVisible(false)} />
                                            <div className='row mt-2'>
                                                <div className='col-3 text-center'>
                                                    <img src={user} alt="user" style={{ width: "80px" }} />
                                                </div>
                                                <div className='col-9'>
                                                    <h1>Reported User:</h1>
                                                    <h2>{userDetails?.FullName}</h2>
                                                    <p className={style.ruEmail}>{userDetails?.EmailAddress} <br /> {userDetails?.PhoneNumber}</p>

                                                    <h3>Joining Date:</h3>
                                                    <p>
                                                        <DateAndTime DateTime={userDetails?.CreatedAt} />
                                                    </p>
                                                    <h3>Business Name: </h3>
                                                    <p>{userDetails?.BusinessName}</p>

                                                    <h3>Location:</h3>
                                                    <p>{userDetails?.Township}, {userDetails?.City}, {userDetails?.Country}</p>
                                                </div>
                                            </div>
                                        </div>}
                                </td>
                                <td style={{ width: "15%" }}>
                                    <DateAndTime DateTime={Reporteduser?.['Reported On']} />
                                </td>
                                <td style={{ width: "15%" }}>{Reporteduser?.['Reported By']}</td>
                                <td style={{ width: "20%" }}>{Reporteduser?.['Reason']}</td>
                                <td style={{ width: "25%" }} className={style.actions}>
                                    {
                                        adminRole?.role === "SuperAdmin" &&
                                        <Link onClick={() => RemoveUser(Reporteduser.ReportedUserID, Reporteduser?.['Reported User'])}>Remove User</Link>
                                    }

                                    {adminRole?.role === "SuperAdmin" &&
                                        <Link onClick={() => DismisReportFunc(Reporteduser.ReportID, Reporteduser?.['Reported User'])}>Dismiss Report</Link>
                                    }
                                </td>
                            </tr>)}

                        </tbody>
                    </table> : <div style={{ display: 'flex', justifyContent: "center" }}>No data found</div>}

                    <Modal
                        show={removeUser}
                        onHide={handleClose}
                        centered className='text-center'
                    >
                        <Modal.Body>
                            <IoMdCloseCircle className="modelClose" onClick={handleClose} />
                            <div className="mt-4 mb-3">
                                <div className="modelText">Are you sure you want to remove user '{nameOfUser}' ?</div>
                                <div className='buttomModel'>
                                    <button className='alertbuttonNo' onClick={() => handleRemoveUse('no')}>No</button>
                                    <button className='alertbuttonYes' onClick={() => handleRemoveUse('yes')}>Yes</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={RemoveReport}
                        onHide={handleClose}
                        centered className='text-center'
                    >
                        <Modal.Body>
                            <IoMdCloseCircle className="modelClose" onClick={handleClose} />
                            <div className="mt-4 mb-3">
                                <div className="modelText">Are you sure you want to dismiss this report?</div>
                                <div className='buttomModel'>
                                    <button className='alertbuttonNo' onClick={() => handleDismiss('no')}>No</button>
                                    <button className='alertbuttonYes' onClick={() => handleDismiss('yes')}>Yes</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div> : <LoaderComponent />}
            </div>
        </div>
    )
}

export default ReportedUsers