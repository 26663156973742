import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/logoHEADER.png';
import LogoutIcon from '../../assets/images/logout_green.png';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import { useQuery } from "react-query";
import { GetAllMessages, GetNotificationSeenStatusApi } from "../../services/adminApis";
import { useDispatch, useSelector } from "react-redux";
import {  ActiveGlobalNotification, GetGlobalNotification } from "../../redux/Actions";
import { HeaderLinks } from "../utils/utils";

const Header = () => {
    const [adminRole, setAdminRole] = useState(null)
    const DispatchFunc = useDispatch();
    //const CurrentStates = useSelector((state) => state.activeGlobalNotificationStatus)
    //Notification fix
    //console.log('CurrentStates--',CurrentStates)


    const CurrentStates = useSelector((state)=>state.getGlobalNotificationStatus)

    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname.split('/')[1];
    const [Logout, SetLogout] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const handleLogout = (status) => {
        if (status === 'yes') {
            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminTokenExpiry');
            navigate('/');
            SetLogout(false)
        }
        else {
            SetLogout(false)
        }
    }
    const { data: getMessages, isFetched } = useQuery('getAllMessages', GetAllMessages, {
        refetchInterval: 10000
    });
    //Notification fix
    //New APi Called TODo
    const { data: NotificationSeenStatusData, isFetched: NotificationSeenStatusFetch } = useQuery('NotificationSeenStatus', GetNotificationSeenStatusApi, {
    });

    //console.log('NotificationSeenStatusData-',NotificationSeenStatusData);
    // const { data: reportedListingData, isFetched:reportedListFetch } = useQuery('reported_listingsHeader', ReportedListingsApi,{
    // });
    // console.log('reportedListingData--', reportedListingData);
    // const { data: reportedUserData, isFetched:reportedUserFetch } = useQuery('reported_UsersHeader', ReportedUserApi,{
    // });
    //console.log('reportedUserData--', reportedUserData);
    const notficationDispatch = (obj) => {
        DispatchFunc(obj)
    }
    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
        setAdminRole(userDetails)
        if (isFetched) {
            let MessageCount = getMessages?.reduce((accumulator, obj) => {
                return accumulator + obj.UnseenMessages
            }, 0);
            setMessageCount([MessageCount])
        }
        //Notification fix
        // if(reportedListFetch){
        //     let reportedListSeen = reportedListingData?.map((item)=>{
        //         return item.Seen
        //     })
        //    // console.log(' return item.Seen--',  reportedListSeen );
        //     // Todo
        //     if(reportedListSeen?.includes(0)){
        //       //  console.log('reportedListSeen- includes(0)' );
        //         notficationDispatch(ActiveReportListNotification(true))
        //     }
        // }
        // if(reportedUserFetch){
        //     let reporteduserSeen = reportedUserData?.map((item)=>{
        //         return item.Seen
        //     })
        //     if(reporteduserSeen?.includes(0)){
        //       //  console.log('reporteduserSeen- includes(0)' );
        //         notficationDispatch(ActiveReportUserNotification(true))
        //     }
        // }

        if (NotificationSeenStatusFetch) {
            // console.log('Abhishek---', NotificationSeenStatusData);
            const notificationStatusValue = Object.values(NotificationSeenStatusData);
            notficationDispatch(GetGlobalNotification(NotificationSeenStatusData))
            // console.log('anbhi==',notificationStatusValue);
            if (notificationStatusValue?.includes(0)) {
                notficationDispatch(ActiveGlobalNotification(true))
                //console.log('reporteduserSeen- includes(0)' );
                //SetNotification(true);
            }
        }

        // },[isFetched,getMessages,reportedListFetch,reportedListingData,reportedUserFetch,reportedUserData]);
    }, [isFetched, getMessages, NotificationSeenStatusFetch, NotificationSeenStatusData]);
    //Notification fix   
    return (
        <header>
            <div className="headerInner">
                <div className="row">
                    <div className="col-2">
                        <Link to="/dashboard"><img src={Logo} alt='logo' style={{ width: "120px" }} /></Link>
                    </div>
                    <div className="col-9 d-flex align-items-center">
                        <ul className="navBarlinkWrapper">
                            <li>
                                <NavLink
                                    to="/dashboard"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                >Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/users"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                >Manage Users</NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/userMessages"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                >User Messages{messageCount[0] !== 0 && <span className="notificationicon">{messageCount}</span>}</NavLink>

                            </li>
                            <li>
                                <NavLink
                                    to="/notification"
                                    className={({ isActive }) => (isActive ? "active" : "")}

                                >Notifications {CurrentStates.ListingReports==0?<span className="notificationiconReport"></span>: CurrentStates.UserReports==0?<span className="notificationiconReport"></span>:CurrentStates.Notifications==0 ? <span className="notificationiconReport"></span> : null}</NavLink>

                            </li>
                            {adminRole?.role === "SuperAdmin" && <li className="toolsMenu">
                                <Dropdown >
                                    <Dropdown.Toggle
                                        className={HeaderLinks.includes(currentUrl) && "activeTools"}>
                                        Tools</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item ><Link to="/activityLog">Activity Log</Link></Dropdown.Item>
                                        <Dropdown.Item ><Link to="/database">Databases</Link></Dropdown.Item>
                                        <Dropdown.Item ><Link to="/userListings">User Listings</Link></Dropdown.Item>
                                        <Dropdown.Item ><Link to="/manageAdmin">Manage Admin User</Link></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>}

                        </ul>
                    </div>
                    <div className="col-1 d-flex align-items-center">
                        <button
                            className="logOutBtn"
                            onClick={() => SetLogout(true)}
                        >
                            <img src={LogoutIcon} alt="logout" />
                            Log Out
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                className="modalStyle text-center"
                show={Logout}
                onHide={() => SetLogout(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <IoMdCloseCircle className="modelClose" onClick={() => SetLogout(false)} />
                    <div className="mt-4 mb-3">
                        <div className="modelText">Are you sure you want to log out ?</div>
                        <div className='buttomModel'>
                            <button className='alertbuttonNo' onClick={() => handleLogout('no')}>No</button>
                            <button className='alertbuttonYes' onClick={() => handleLogout('yes')}>Yes</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </header>
    )
}
export default Header;