import React, { useEffect, useState } from 'react'
import Style from './manageAdmin.module.css';
import { useMutation, useQuery } from 'react-query';
import { GetAdminRolesApi, RegisterNewAdminApi } from '../../../services/adminApis';
import { toast } from 'react-toastify';


const ManageAdmin = () => {
    const defaultOption = 'Select Role';
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const {data:roles, isLoading:rolesLoading} = useQuery('admin_roles',GetAdminRolesApi);
    const [emailInput, setEmailInput] = useState('');
    const [adminRole, setAdminRole] = useState('');
    const handleSelectRole =(e)=>{
       let value = e.target.value;
       setAdminRole(value)
       setSelectedOption(value)
    }
    const {mutate:sendAdminDetails} = useMutation('register_admin',RegisterNewAdminApi,{
        onSuccess:(data)=>{
             toast.success(data.message)
        },
    });
    const addNewAdmin =()=>{
        const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(emailInput ===''){
            toast.error("Please enter Email Id.");
            return;
         }
        else if(!regex_email.test(emailInput)){
           toast.error("Please enter valid Email Id.");
           return;
        }
        else if(adminRole === ''){
            toast.error("Please select role.");
            return;
        }
        else{
            let obj = {
                "EmailAddress": emailInput,
                "Role": adminRole
            }
            sendAdminDetails(obj)
            setEmailInput('')
            setAdminRole('')
            setSelectedOption(defaultOption)
        }
    }
useEffect(()=>{
    
},[roles])
    return (
        <div className={Style.manageAdmin}>
            <div className="row">
                <div className="col-md-12 mb-4 mt-3">
                    <div className="col-md-8"><h2>Manage Admin User</h2></div>
                </div>
                <div className="col-md-12">
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form_label'>Admin Email</label>
                            <input 
                               type="text" 
                               name="userName" 
                               className='form_control' 
                               autoComplete='off'
                               value={emailInput}
                               onChange={(e)=>setEmailInput(e.target.value)}
                            />
                        </div>
                        <div className='col-3'> 
                            <select value={selectedOption} className='form_select form_mt6' onChange={(e)=>handleSelectRole(e)}>
                                <option defaultValue={selectedOption}>{selectedOption}</option>
                                {roles?.map((role,index)=><option value={role?.role_name} key={index}>{role?.role_name}</option>)}
                            </select>
                        </div>
                        {/* <div className='col-5'> 
                            <button className='btn_dark form_mt6'>Send Email for account creation</button>
                        </div> */}
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <button 
                              className='btn_primary'
                              onClick={addNewAdmin}
                             >Add New Admin</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageAdmin