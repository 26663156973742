import React, { useEffect, useState } from 'react'
import Style from '../../pages/userMessages/user_messages.module.css';
import { useDispatch } from 'react-redux';
import { GetUserMessageIdAction } from '../../redux/Actions';
import { useMutation, useQueryClient } from 'react-query';
import { ChatViewApi } from '../../services/adminApis';

const ChatUsers = ({ usersList, searchChat }) => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState('')
  let searchChatValue = searchChat.toLowerCase();
  let queryClient = useQueryClient()
  const { mutate: chatViewFunc } = useMutation('chatView', ChatViewApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAllMessages')
    }
  });
  const dispatchUserId = (userid, userName) => {
    setSelectedId(userid)
    dispatch(GetUserMessageIdAction(userid, userName));
    chatViewFunc({
      "userId": userid
    })
  }

  return (
    <>
      {usersList?.filter((item) => {
        return item.FullName?.toLowerCase().includes(searchChatValue) ?
          item : item.LastMessageBy?.toLowerCase().includes(searchChatValue) ?
            item : item.LastMessageText?.toLowerCase().includes(searchChatValue) ?
              item : item.LastMessageTime?.toLowerCase().includes(searchChatValue)
      })?.map((user, index) => <div
        className='row mx-0 chatCard mb-3'
        key={index}
        onClick={() => {
          dispatchUserId(user.UserID, user.FullName)}
        }
        style={{
          border: user.UserID === selectedId ? "2px solid #0059B985" : user.UnseenMessages > 0? "2px solid #F2BD3887" : "none",
          boxShadow: user.UnseenMessages > 0 && user.UserID !== selectedId? "0px 0px 7px 0px #F2BD3887" : "0px 0px 4px 1px #D2D2D26E",
        }}
      >
        <div className={Style.userProfile+' '+'col'}>
          <div className={Style.userName}>
            {user.FullName?.split(' ')[0]?.substring(0, 1)}
            {user.FullName?.split(' ')[1]?.substring(0, 1)}
          </div>
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col-9'>
              <div className={Style.fullName} >{user.FullName}</div>
              <div className={Style.message}> 
                  {user.LastMessageBy?.split(' ')[0]}<span className={Style.wordWrap}>: {user?.LastMessageText}</span>   
              </div>
            </div>
            <div className='col'>
              <div className={Style.timeWrapper}>
                <p> {new Date(user?.LastMessageTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',hour12: false })}</p>
                <p>{new Date(user?.LastMessageTime).toLocaleDateString('en-GB', { day: "numeric", month: "numeric", year: "numeric" })}</p>
              </div>
            </div> 
          </div>
        </div>

      </div>)}
    </>
  )
}

export default ChatUsers;