export const baseUrl = process.env.REACT_APP_API_URL;
// export const cmsUrl = process.env.REACT_APP_CMS_API_URL;
// export const app_key = process.env.REACT_APP_KEY;
// export const app_name = process.env.REACT_APP_NAME;
// export const site_key = process.env.REACT_APP_SITE_KEY;
const baseLink = 'api/admin'
export const apiPath = {
    adminLogin: baseLink + '/login',
    metricsData: baseLink + '/metrics',
    AllUsers:baseLink + '/users',
    allMessages:baseLink + '/messages',
    sendMessage:baseLink + '/messages/user',
    chatView:baseLink + '/messages/chatview',
    reportedListings:baseLink + '/report/listings',
    singleListing:baseLink + '/listings',
    systemNotification:baseLink + '/system-notifications',
    activityLog:baseLink + '/activity-log',
    Report:baseLink + '/reports',
    DismissReport:baseLink + '/report',
    ReportedUsers:baseLink + '/report/users',
    ReportSeen:baseLink + '/report/mark-reports-seen',
    SellerListings:baseLink + '/listings/seller',
    BuyerListings:baseLink + '/listings/buyer',
    GetAllCategoriesList:baseLink + '/categories',
    Categories:baseLink + '/categories',
    SubCategories:baseLink + '/subcategories',
    AddSubCategories:baseLink + '/subcategories/add-sub-category',
    getAllAdminRoles:baseLink + '/get-roles',
    RegisterNewAdmin:baseLink + '/register',
    ForgotPassword:baseLink + '/forgot-password',
    setPassword:baseLink + '/set-password',
    AddSubCategoriesToMaster:baseLink + '/subcategories/add-new-sub-category-master',
    AddCategoryToMasterList:baseLink + '/categories/add-category-master',
    AddSubCatetoExistingList:baseLink + '/subcategories/add-translation-sub-category-master',
    GetNotificationSeenStatus:baseLink+"/report/get-seen-status",
    MarkSystemNotificationSeen:baseLink+"/mark-notifications-as-seen"
}