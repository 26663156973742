import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { MarkSystemNotificationSeenApi, SystemNotificationApi } from '../../services/adminApis';
import LoaderComponent from '../loader/loader';
import DateAndTime from '../DateAndTime/date_time';
import { TokenExpiry } from '../../pages/utils/tokenExpiry';

const SystemNotifications = () => {
    const activeTab = useSelector((state) => state.activetab);
     //Notification fix
    const queryClient = useQueryClient()    
    const {mutate:reportseenFunc} = useMutation('MarkSystemNotificationSeenApi',MarkSystemNotificationSeenApi,{
        onSuccess:()=>{
            queryClient.invalidateQueries('NotificationSeenStatus')
        }
    });
    const { mutate: activeCurrentTab, data: systemNotificationData, isLoading } = useMutation('systemNotification', SystemNotificationApi,
    {
        onSuccess:()=>{
        queryClient.invalidateQueries('NotificationSeenStatus')
    }}
    );

    useEffect(() => {
        if (activeTab === 'sn') {
            activeCurrentTab()
       
         //Notification fix
        return ()=>{
            if(!TokenExpiry(localStorage.getItem('adminTokenExpiry'))){
                reportseenFunc({})
                // notficationDispatch(ActiveReportListNotification(false))
            }
        }
    }
    }, [activeTab])

    return (
        <div className='notificationWraper'>
            <div className="row">
                <div className="col-md-12">
                    {isLoading === false ? <table className="table table-striped table-bordered dashTable tlTable">
                        <thead>
                            <tr>
                                <th style={{ width: "30%" }}>NAME OF USER</th>
                                <th style={{ width: "50%" }}>ACTIONS</th>
                                <th style={{ width: "20%" }}>DATE & TIME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {systemNotificationData?.map((system, index) => <tr key={index}>
                                <td style={{ width: "30%" }}>{system?.['NAME OF USER']}</td>
                                <td style={{ width: "50%" }}>{system?.['ACTIONS']}</td>
                                <td style={{ width: "20%" }}>
                                   <DateAndTime DateTime={system?.['DATE & TIME']}/>
                                </td>
                            </tr>)}
                        </tbody>
                    </table> : <LoaderComponent />}
                </div>
            </div>
        </div>
    )
}

export default SystemNotifications