import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Close from '../../assets/images/close_white.png';
import style from '../../pages/notifications/notification.module.css'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { DismisReportApi, RemoveListingApi, ReportSeenApi, ReportedListingsApi, SingleListingApi } from '../../services/adminApis';
import LoaderComponent from '../loader/loader';
import ListingStatus from '../listingStatus/listingStatus';
import NoImage from '../../assets/images/no_image.jpg';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import { TokenExpiry } from '../../pages/utils/tokenExpiry';
import DateAndTime from '../DateAndTime/date_time';

const ReportedListings = () => {
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const [adminRole, setAdminRole] = useState(null)
    const handleClose = () => setShow(false);
    const [RemoveListing, setRemoveListing] = useState(false);
    const [RemoveReport, setRemoveReport] = useState(false)
    const [ListingId, setListingId] = useState();
    const [ReportId, setReportId] = useState();

    const activeTab = useSelector((state) => state.activetab);

    const { mutate: activeCurrentTab, data: reportedListingData, isLoading } = useMutation('reported_listings', ReportedListingsApi, {
        onSuccess: () => {
            //Notification fix
            // console.log('abhishek: ',reportedListingData);
            queryClient.invalidateQueries('NotificationSeenStatus')
            //queryClient.invalidateQueries('reported_listingsHeader')
        }
    });
    const { mutate: singleListingFunc, data: singleListingsData } = useMutation('singlelisting', SingleListingApi)

    const { mutate: reportseenFunc } = useMutation('report_seen', ReportSeenApi, {
        onSuccess: () => {
            //Notification fix
            queryClient.invalidateQueries('NotificationSeenStatus')
            //queryClient.invalidateQueries('reported_listingsHeader')
        }
    });
    const { mutate: removeListingLoadFuc } = useMutation('remove_listing', RemoveListingApi, {
        onSuccess: (data) => {
            activeCurrentTab();
            let successMessage = data.message;
            toast.success(successMessage)
        }
    })
    const { mutate: dismissReport } = useMutation('dismiss_report', DismisReportApi, {
        onSuccess: (data) => {
            activeCurrentTab();
            let successMessage = data.message;
            toast.success(successMessage)
        }
    })
    const handleShow = (listingId) => {
        setShow(true);
        singleListingFunc(listingId)
    }
    const removeListing = (listingId) => {
        setListingId(listingId);
        setRemoveListing(true);

    }
    const removeListingFunc = (status) => {
        if (status === 'yes') {
            setRemoveListing(false)
            removeListingLoadFuc(ListingId)
        }
        else {
            setRemoveListing(false)
        }
    }
    const DismisReportFunc = (ReportId) => {
        setRemoveReport(true)
        setReportId(ReportId)
    }
    const removeReport = (status) => {
        if (status === 'yes') {
            setRemoveReport(false)
            dismissReport(ReportId)
        }
        else {
            setRemoveReport(false)
        }
    }
    // const notficationDispatch =(obj)=>{
    //     dispatch(obj)
    // }
    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
        setAdminRole(userDetails);
        if (activeTab === 'rl') {
            activeCurrentTab({})
            return () => {
                if (!TokenExpiry(localStorage.getItem('adminTokenExpiry'))) {
                    reportseenFunc({
                        "reportType": "Listing"
                    })
                    // notficationDispatch(ActiveReportListNotification(false))
                }
            }
        }
    }, [activeTab])

    return (
        <div className='notificationWraper'>
            <div className="row">
                {isLoading === false ? <div className="col-md-12">
                    {reportedListingData !== undefined && reportedListingData.length > 0 ? <table className="table table-striped table-bordered dashTable tlTable">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>LISTING NO</th>
                                <th style={{ width: "20%" }}>REPORTED BY</th>
                                <th style={{ width: "15%" }}>REPORTED ON</th>
                                <th style={{ width: "20%" }}>REASON</th>
                                <th style={{ width: "35%" }}>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportedListingData?.map((listing, index) => <tr key={index}>
                                <td style={{ position: "relative", width: "10%" }}>{listing?.Seen === 0 && <span className='notificationiconReportTable'></span>}{listing?.['Listing No.']}</td>
                                <td style={{ width: "20%" }}>{listing?.['Reported By']}</td>
                                <td style={{ width: "15%" }}><DateAndTime DateTime={listing?.['Reported On']} /></td>
                                <td style={{ width: "20%" }}>{listing?.Reason}</td>
                                <td style={{ width: "35%" }} className={style.actions}>
                                    <Link onClick={() => handleShow(listing?.['Listing No.'])}>View Listing</Link>
                                    {
                                        adminRole?.role === "SuperAdmin" &&
                                        <Link onClick={() => removeListing(listing?.['Listing No.'])}>Remove Listing</Link>
                                    }
                                    {
                                        adminRole?.role === "SuperAdmin" &&
                                        <Link onClick={() => DismisReportFunc(listing?.['ReportID'])}>Dismiss Report</Link>

                                    }     </td>
                            </tr>)}
                        </tbody>
                    </table> : <div style={{ display: "flex", justifyContent: "center" }}>No data found.</div>}
                    <Modal show={show} centered className="viewListing">
                        <Modal.Body className='text-center' style={{ backgroundColor: singleListingsData?.ListingType === "Seller" ? "#E0EECE" : "#FDF3DB" }}>
                            <ListingStatus listingStatus={singleListingsData?.ListingStatus} />
                            <div className='row border-bottom py-2 mt-3'>
                                <div className='col-6 listingID'>{singleListingsData?.ListingID}</div>
                                <div className='col-6'><h6><DateAndTime DateTime={singleListingsData?.CreatedAt} /></h6></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{singleListingsData?.FullName}</h5></div>
                                <div className='col-6'><h5>{singleListingsData?.ImageURLs != '' && singleListingsData?.ImageURLs !== null ? `${singleListingsData?.ImageURLs.split(',').length}  image${singleListingsData?.ImageURLs.split(',').length > 1 ? "'s" : ''}` : ''}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12'>
                                    <Carousel
                                        className='listingSlider'
                                        interval={null}
                                        indicators={false}
                                        controls={singleListingsData?.ImageURLs != '' && singleListingsData?.ImageURLs != null && singleListingsData?.ImageURLs.split(',').length > 1 ? true : false}
                                    >
                                        {singleListingsData?.ImageURLs != '' && singleListingsData?.ImageURLs != null ? singleListingsData?.ImageURLs.split(',').map((item, index) => <Carousel.Item interval={1000} key={index}>
                                            <img src={item} alt="aa" />
                                        </Carousel.Item>) :
                                            <Carousel.Item>
                                                <img src={NoImage} alt="aa" />
                                            </Carousel.Item>}
                                    </Carousel>

                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{singleListingsData?.SubCategoryName}</h5></div>
                                <div className='col-6'><h5>{singleListingsData?.CategoryName}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>Ks.{singleListingsData?.PriceMax}/{singleListingsData?.Unit}</h5></div>
                                <div className='col-6'><h5>{singleListingsData?.Quantity} {singleListingsData?.Unit}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12 listDescription'>
                                    <p>{singleListingsData?.Description}</p>
                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{singleListingsData?.Township}</h5></div>
                                <div className='col-6'><h5>{singleListingsData?.City}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>Delivery {singleListingsData?.DeliveryStatus === "Possible" ? "Available" : "Not Available"}</h5></div>
                                <div className='col-6'><h5>Site-Inspection {singleListingsData?.DeliveryStatus === "Available" ? "Allowed" : "Not Allowed"}</h5></div>
                            </div>
                        </Modal.Body>
                        <Link className='modalClose' onClick={handleClose}><img src={Close} alt="aa" /> Close</Link>
                    </Modal>
                    <Modal
                        className="modalStyle text-center"
                        show={RemoveListing}
                        onHide={() => setRemoveListing(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <IoMdCloseCircle className="modelClose" onClick={() => setRemoveListing(false)} />
                            <div className="mt-4 mb-3">
                                <div className="modelText">Are you sure you want to remove listing from this user?</div>
                                <div className='buttomModel'>
                                    <button className='alertbuttonNo' onClick={() => removeListingFunc('no')}>No</button>
                                    <button className='alertbuttonYes' onClick={() => removeListingFunc('yes')}>Yes</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        className="modalStyle text-center"
                        show={RemoveReport}
                        onHide={() => setRemoveReport(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <IoMdCloseCircle className="modelClose" onClick={() => setRemoveReport(false)} />
                            <div className="mt-4 mb-3">
                                <div className="modelText">Are you sure you want to dismiss this report?</div>
                                <div className='buttomModel'>
                                    <button className='alertbuttonNo' onClick={() => removeReport('no')}>No</button>
                                    <button className='alertbuttonYes' onClick={() => removeReport('yes')}>Yes</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div> : <LoaderComponent />}
            </div>
        </div>
    )
}

export default ReportedListings