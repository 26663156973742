import React from 'react'
import Style from './popupcomponentStyle.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Carousel from 'react-bootstrap/Carousel';
import ListingStatus from '../listingStatus/listingStatus';
import NoImage from '../../assets/images/no_image.jpg';
import DateAndTime from '../DateAndTime/date_time';

const ViewListing = ({ViewListingData}) => {
    return (
        <div className={Style.viewListingWrapper}>
            <>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    //modules={[Pagination]}
                    className="mySwiper"
                >
                   {ViewListingData?.map((listing,index)=><SwiperSlide key={index} className={Style.viewListingCard} style={{ backgroundColor: listing?.ListingType === "Seller"?"#E0EECE":"#FDF3DB"}}>
                        <ListingStatus 
                           listingStatus = {listing?.ListingStatus}
                        />
                        <div className={Style.topfont+' '+'row border-bottom py-2 mt-4'}>
                            <div className='col-6 listingID'>{listing?.ListingID}</div>
                            <div className='col-6'>
                                <DateAndTime DateTime={listing?.CreatedAt}/>
                            </div>
                        </div>
                        <div className={Style.topfontTwo+' '+'row border-bottom py-2'}>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.FullName}</div>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.Images!==''?`${listing?.Images.split(',').length} image${listing?.Images.split(',').length>1?"'s":''}`:''}</div>
                        </div>
                        <div className='row border-bottom py-2'>
                            <div className='col-md-12'>
                                <Carousel 
                                   className='listingSlider' 
                                   interval={null}
                                   indicators={false}
                                   controls={listing?.Images!==''&&listing?.Images!==null&&listing?.Images.split(',').length>1?true:false}
                                >
                                    {listing?.Images!==''&&listing?.Images!==null?listing?.Images.split(',').map((item,index)=><Carousel.Item interval={1000} key={index}>
                                        <img src={item} alt="aa" />
                                    </Carousel.Item>):
                                    <Carousel.Item>
                                        <img src={NoImage} alt="aa" />
                                    </Carousel.Item>
                                    }
                                </Carousel>

                            </div>
                        </div>
                        <div className='row border-bottom py-2'>
                        <div className={Style.topfontTwo+' '+'col-6'}>{listing?.SubCategoryName_EN !== '' && listing?.SubCategoryName_EN !== null ? listing?.SubCategoryName_EN : listing?.SubCategoryName_MM}</div>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.CategoryName_EN !== '' && listing?.CategoryName_EN !== null ? listing?.CategoryName_EN : listing?.CategoryName_MM}</div>
                            
                            
                        </div>
                        <div className='row border-bottom py-2'>
                            <div className={Style.topfontTwo+' '+'col-6'}>Ks.{listing?.PriceMax}/{listing?.Unit}</div>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.Quantity} {listing?.Unit}</div>
                        </div>
                        <div className='row border-bottom py-2'>
                            <div className='col-md-12 listDescription'>
                                <p>{listing?.Description}</p>
                            </div>
                        </div>
                        <div className='row border-bottom py-2'>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.Township}</div>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.City}</div>
                        </div>
                        <div className='row border-bottom py-2'>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.DeliveryStatus}</div>
                            <div className={Style.topfontTwo+' '+'col-6'}>{listing?.SiteInspectionStatus}</div>
                        </div>
                    </SwiperSlide>)}
                </Swiper>
            </>
        </div>
    )
}

export default ViewListing;