import React from 'react'
import { Oval } from 'react-loader-spinner';

const LoaderComponent = () => {
  return (
    <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Oval
      visible={true}
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </div>
  )
}

export default LoaderComponent;