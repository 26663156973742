import React, { useMemo } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import ListingStatus from '../listingStatus/listingStatus';
import DateAndTime from '../DateAndTime/date_time';
import NoImage from '../../assets/images/no_image.jpg';

const BuyerGridView = ({ BuyerDataset, searchInput}) => {
    let InputValLower = searchInput.toLowerCase();
    const getFilterArray = useMemo(() => {
        let filterdta = BuyerDataset?.filter((item) => {
            const timestampDate = new Date(item?.PostedOn);
            // Format the date as a string with date and time
            const formattedTimestamp = timestampDate.toLocaleString('en-GB');
            let removeComma = formattedTimestamp.split(',');
            let reArrangeDate = removeComma[0]+' '+removeComma[1];
            return  item.Category?.toLowerCase().includes(InputValLower) ||
            item.City?.toLowerCase().includes(InputValLower) ||
            item.DeliveryStatus?.toLowerCase().includes(InputValLower) ||
            item.Description?.toLowerCase().includes(InputValLower) ||
            item.ListingStatus?.toLowerCase().includes(InputValLower) ||
            item.PostedBy?.toLowerCase().includes(InputValLower) ||
            item.PostedOn?.toLowerCase().includes(InputValLower) ||
            item.PriceMax?.toLowerCase().includes(InputValLower) ||
            item.PriceMin?.toLowerCase().includes(InputValLower) ||
            item.Quantity?.toLowerCase().includes(InputValLower) ||
            item.SiteInspectionStatus?.toLowerCase().includes(InputValLower) ||
            item.Subcategory?.toLowerCase().includes(InputValLower) ||
            item.Township?.toLowerCase().includes(InputValLower) ||
            item.Unit?.toLowerCase().includes(InputValLower) ||
            item.ListingID?.toString().includes(InputValLower)||
            reArrangeDate.toLowerCase().includes(InputValLower)
        })
        return filterdta
    }, [InputValLower,BuyerDataset])
    return (
        <div className="gridViewWrapper my-4">
            <div className='row gridScroll'>
                {getFilterArray!==undefined&&getFilterArray.length>0?getFilterArray?.map((buyer, index) => <div className='col-md-3 mb-4' key={index} style={{ position: "relative" }}>
                    <div className="viewListing">
                        <div className='publishedWrapper text-center'>
                            <div style={{
                                left: "15px",
                                top: "3px",
                                position: "absolute",
                                width: "100%"
                            }}>
                                <ListingStatus
                                    listingStatus={buyer?.ListingStatus}
                                />
                            </div>
                            <div className='row border-bottom py-2 mt-3'>
                                <div className='col-6'><h5>{buyer?.ListingID}</h5></div>
                                <div className='col-6'><h5>
                                        <DateAndTime DateTime={buyer?.PostedOn}/>
                                    </h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{buyer?.PostedBy}</h5></div>
                                <div className='col-6'><h5>{buyer?.Images!==''?`${buyer?.Images.split(',').length} image${buyer?.Images.split(',').length>1?"'s":''}`:''}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12'>
                                    <Carousel 
                                      className='listingSlider'
                                      interval={null}
                                      indicators={false}
                                      controls={buyer?.Images!==''&&buyer?.Images!==null&&buyer?.Images.split(',').length>1?true:false}  
                                    >
                                    {buyer?.Images!==''&&buyer?.Images!==null?buyer?.Images.split(',').map((item,index)=><Carousel.Item interval={1000} key={index}>
                                        <img src={item} alt="aa" />
                                    </Carousel.Item>):
                                    <Carousel.Item>
                                        <img src={NoImage} alt="aa" />
                                    </Carousel.Item>
                                    }
                                    </Carousel>

                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                {/* <div className='col-6'><h5>{buyer?.Subcategory}</h5></div>
                                <div className='col-6'><h5>{buyer?.Category}</h5></div> */}
                                  <div className='col-6'><h5>   {buyer?.CategoryName_EN!==''&&buyer?.CategoryName_EN!==null?buyer?.CategoryName_EN:buyer?.CategoryName_MM}</h5></div>
                                <div className='col-6'><h5>{buyer?.SubcategoryName_EN!==''&&buyer?.SubcategoryName_EN!==null?buyer?.SubcategoryName_EN:buyer?.SubcategoryName_MM}</h5></div>
                            
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>Ks.{buyer?.PriceMax}/{buyer?.Unit}</h5></div>
                                <div className='col-6'><h5>{buyer?.Quantity} {buyer?.Unit}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12'>
                                    <p>{buyer?.Description}</p>
                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{buyer?.Township}</h5></div>
                                <div className='col-6'><h5>{buyer?.City}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{buyer?.DeliveryStatus}</h5></div>
                                <div className='col-6'><h5>{buyer?.SiteInspectionStatus}</h5></div>
                            </div>
                        </div>
                    </div>
                </div>):<div>No data found.</div>}
            </div>
        </div>
    )
}

export default BuyerGridView;