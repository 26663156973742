import React, { useEffect, useState } from 'react';
import style from './dashboard.module.css';
import DashboardTable from '../../components/table_components/dashboard_table';
import BarGraph from '../../components/graph/barGraph';
import Download_black from '../../assets/images/download_black.png';
import { IoIosArrowDown } from "react-icons/io";
import { MonthData } from '../utils/monthDataset';
import { useQuery } from 'react-query';
import { DashBoardMatric } from '../../services/adminApis';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import LoaderComponent from '../../components/loader/loader';
import GenerateExcelfile from '../../components/generateExcelfile/generateExcelfile';
import { GenerateRandom } from '../../components/generateRandomNumber/generateRandomNumber';

const Dashboard = () => {
  let TimeStamp = GenerateRandom
  const date = new Date();
  const currentYear = date.getFullYear();
  //const PrevYear = currentYear - 1;
  const [toggleValue, setToggleValue] = useState(false);
  const [dateShow, setDateShow] = useState(false);
  
  let currMonth = date.getMonth()+1
  const currentMonthLoop = MonthData?.filter((item)=>item.id === currMonth)
  const [currentMonthName, setCurrentMonthName] = useState(currentMonthLoop[0].name);
  const [currentMonthId, setCurrentMonthId] = useState(currMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [monthCount, setMonthCount] = useState(6);
  const [generateExcel, setGenerateExcel] = useState(false)
  const queryKey = ['metricData', {
    year: selectedYear,
    startingMonth: currentMonthId,
    monthCount: monthCount
  }]
  const { isLoading, data } = useQuery(queryKey, DashBoardMatric,
    { retry: 0 });
  const NewUserCountList = data && data.NewUserCount;
  const MonthList = NewUserCountList && NewUserCountList.flatMap(Object.keys);
  const TotalNumberOfusers = data && data.TotalUserCount;
  const SellerListingsCount = data && data.SellerListingsCount;
  const BuyerListingsCount = data && data.BuyerListingsCount;
  const MessagesCount = data && data.MessagesCount;
  const UserAggregate = data && data.UserAggregate;
  const ConnectionCount = data?.ConnectionsCount

  const toggleMode = (e) => {
    setToggleValue(e.target.checked);
  }
  const selectCurrentMonth = (id, month) => {
    setDateShow(false)
    setCurrentMonthId(id)
    setCurrentMonthName(month)
  }
  const closeDate = () => {
    setDateShow(false)
  }
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2024; 
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  useEffect(() => {
    document.body.addEventListener('click', closeDate);
  }, [])
  if (isLoading) {
    return (
      <LoaderComponent />
    )
  }

  return (
    <div className={style.subNavWrapper}>
      <div className='row'>
        <div className='col-3'>
          <div className="form-check form-switch">
            <div className={style.switchalign}>
              <div className='ps-0 pe-2'>
                <label
                  style={{ color: toggleValue === true ? "#4A4A4A" : "#5BA15B" }}
                >TABLE MODE</label>
              </div>
              <div>
                <input
                  onChange={(e) => toggleMode(e)}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={toggleValue}
                />
              </div>
              <div>
                <label
                  style={{ color: toggleValue === true ? "#E27614" : "#4A4A4A" }}
                >
                  GRAPH MODE
                </label></div>
            </div>
          </div>
        </div>
        <div className='col-4 px-0'>
          <div className={style.filterWrapper}>
            <label>View From :</label>
            <label className="arrowDown selectionBox" onClick={(e) => {
              e.stopPropagation();
              setDateShow(!dateShow)
            }}>{currentMonthName} <IoIosArrowDown /></label>
            {dateShow && <ul>
              {MonthData.map((month, index) => <li
                key={index}
                onClick={() => selectCurrentMonth(month.id, month.name)}>
                {month.name}
              </li>)}
            </ul>}
          </div>

          <div className={style.filterWrapper + ' ' + style.filterWrapper2}>
            <select
              onChange={(e) => setSelectedYear(e.target.value)}
              value={selectedYear}
            >
              {getYearOptions()?.map((year,index)=><option key={index} value={year}>{year}</option>)}
            </select>

          </div>
        </div>
        <div className='col-3'>
          <div className={style.generateSelect}>
            <label>Generate for :</label>
            <select
              onChange={(e) => setMonthCount(e.target.value)}
              value={monthCount}
            >
              <option value={6}>6 Months</option>
              <option value={12}>1 year</option>
            </select>
          </div>
        </div>
        <div className='col-2'>
          <div className={style.download}>
            <button
              onClick={() => setGenerateExcel(true)}
            ><img src={Download_black} alt="download_black" />Download Metrics</button>
          </div>

        </div>
      </div>
      <div className='pt-4'>
        {data !== undefined ? <>
          {toggleValue === false ? <DashboardTable
            MonthList={MonthList}
            NewUserCountList={NewUserCountList}
            SellerListingsCount={SellerListingsCount}
            BuyerListingsCount={BuyerListingsCount}
            MessagesCount={MessagesCount}
            UserAggregate={UserAggregate}
            TotalNumberOfusers={TotalNumberOfusers}
            ConnectionCount={ConnectionCount}
          /> :
            <div className={style.chartWrapper}>
              <Swiper
                navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                modules={[Navigation]}
                className="mySwiper">
                <SwiperSlide >
                  <div className='row mx-0'>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Number of New Users"
                        MonthLabels={MonthList}
                        graphData={NewUserCountList}
                      />
                    </div>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Total Number of Users"
                        MonthLabels={MonthList}
                        graphData={TotalNumberOfusers}
                        UserAggregateData={UserAggregate}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='row mx-0'>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Number of New Seller Listings"
                        MonthLabels={MonthList}
                        graphData={SellerListingsCount}
                      />
                    </div>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Number of New Buyer Listings"
                        MonthLabels={MonthList}
                        graphData={BuyerListingsCount}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='row mx-0'>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Number Of Messages Exchanged"
                        MonthLabels={MonthList}
                        graphData={MessagesCount}
                      />
                    </div>
                    <div className={'col-6' + ' ' + style.borderColor}>
                      <BarGraph
                        title="Number of Connections Made"
                        MonthLabels={MonthList}
                        graphData={ConnectionCount}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <button className="arrow-left arrow"><IoIosArrowForward /></button>
              <button className="arrow-right arrow"><IoIosArrowBack /></button>
            </div>
          }
        </> : <div>No data</div>}
      </div>

      {generateExcel && <GenerateExcelfile
        selectedYear={selectedYear}
        currentMonthName={currentMonthName}
        timeStamp={TimeStamp}
        setGenerateExcel={setGenerateExcel}
        MonthList={MonthList}
        NewUserCountList={NewUserCountList}
        SellerListingsCount={SellerListingsCount}
        BuyerListingsCount={BuyerListingsCount}
        MessagesCount={MessagesCount}
        UserAggregate={UserAggregate}
        TotalNumberOfusers={TotalNumberOfusers}
        ConnectionCount={ConnectionCount}
      />}
    </div>
  )
}

export default Dashboard;