import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import { useMutation, useQueryClient } from 'react-query';
import { AddSubCatToExistingListApi} from '../../services/adminApis';
import { toast } from 'react-toastify';

const AddSubCatToExistingMasterList = ({
    subCategoryDetails,
    setSubCategoryDetails,
    SubCategoryPopup,
    setSubCategoryPopup
}) => {

    let queryClient = useQueryClient();
        const {mutate:addSubcatMutation} = useMutation('add_subCatToExistingMaster',AddSubCatToExistingListApi,{
            onSuccess:(data)=>{
               toast.success(data.message);
               queryClient.invalidateQueries('getAllCategories')
            }
        });
    const addToMaterList =()=>{
        let obj ={
            "subCategoryName_EN": subCategoryDetails.subCategoryName_EN,
            "subCategoryName_MM": subCategoryDetails.subCategoryName_MM,
            "parentCategoryID": subCategoryDetails.parentCategoryID,
            "categoryID": subCategoryDetails.categoryID,
        }
        addSubcatMutation(obj);
        setSubCategoryPopup(false)
        setSubCategoryDetails({
            "subCategoryName_EN": '',
            "subCategoryName_MM": '',
            "parentCategoryID":'',
            "categoryID": '',
        })
    }
    return (
        <Modal
            className="modalStyle text-center subcategorymodel"
            show={SubCategoryPopup}
            onHide={() => setSubCategoryPopup(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <IoMdCloseCircle className="modelClose"
                    onClick={() => setSubCategoryPopup(false)}
                />
                <div className="mt-4 mb-3">
                    <h4 className='db-pop-title' style={{ textAlign: "left" }}>Add Subcategory to Master List</h4>
                    <div className='row mt-3' style={{ textAlign: "left" }}>
                        <h4 className="catTitle" style={{ fontSize: 16 }}>Subcategory</h4>
                        <div className='col-6 catInput'>
                            <label>Label in English</label>
                            <input
                                type="text"
                                value={subCategoryDetails.subCategoryName_EN}
                                onChange={(e) => setSubCategoryDetails({
                                    ...subCategoryDetails,
                                    subCategoryName_EN: e.target.value
                                })}
                            />
                        </div>
                        <div className='col-6 catInput'>
                            <label>Label in Burmese</label>
                            <input
                                type="text"
                                value={subCategoryDetails.subCategoryName_MM}
                                onChange={(e) => setSubCategoryDetails({
                                    ...subCategoryDetails,
                                    subCategoryName_MM: e.target.value
                                })}
                            />
                        </div>
                        <div className='row mt-4 text-center px-0'>
                            <div className='col-12 px-0'
                                style={{ textAlign: "right" }}
                            >
                                <button
                                    className='addNewSubcat'
                                    style={{ background: subCategoryDetails.subCategoryName_EN!==''&&subCategoryDetails.subCategoryName_MM!==''?"#5BA15B":"#A5A5A5", color: "#fff", padding: "8px 40px" }}
                                    onClick={addToMaterList}
                                    disabled={subCategoryDetails.subCategoryName_EN!==''&&subCategoryDetails.subCategoryName_MM!==''?false:true}

                                >Add to Master List <FaArrowRight style={{ fontSize: "16px" }} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddSubCatToExistingMasterList;