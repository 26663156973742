const DashboardTable = (
    {  MonthList, 
       NewUserCountList, 
       SellerListingsCount, 
       BuyerListingsCount, 
       MessagesCount, 
       UserAggregate, 
       TotalNumberOfusers,
       ConnectionCount
    }) => {
    return (
        <div>
            <table className="table table-striped table-bordered dashTable">
                <thead className="dashTableLulu">
                    <tr>
                        <th style={{width:"30%"}}>Metric</th>
                        {MonthList&&MonthList.map((month,index)=><th key={index}>{month}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of New Users</td>
                        {NewUserCountList&&NewUserCountList.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                     
                    </tr>
                    <tr>
                        <td>Total Number of Users</td>
                        {TotalNumberOfusers&&TotalNumberOfusers.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                        <td>{UserAggregate?.Avg}</td>
                        <td>{UserAggregate?.TotalUserCount}</td>
                    </tr>
                    <tr>
                        <td>Number of New Seller Listings</td>
                        {SellerListingsCount&&SellerListingsCount.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                    </tr>
                    <tr>
                        <td>Number of New Buyer Listings</td>
                        {BuyerListingsCount&&BuyerListingsCount.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                    </tr>
                    <tr>
                        <td>Number of Messages Exchanged</td>
                        {MessagesCount&&MessagesCount.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                    </tr>
                    <tr>
                        <td>Number of Connections Made</td>
                        {ConnectionCount&&ConnectionCount.map((item, index)=><td key={index}>{Object.values(item)}</td>)}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DashboardTable;