import React, { useEffect, useRef, useState } from 'react'
import Style from '../../pages/userMessages/user_messages.module.css';
import SendIcon from '../../assets/images/send_message_purple.png';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetCurrrentMessagesApi, SendMessageApi } from '../../services/adminApis';
import LoaderComponent from '../loader/loader';
import DateAndTime from '../DateAndTime/date_time';

const Messages = () => {
    const divRef = useRef(null);
    const userDetails = useSelector((state) => state);
    const [adminMessage, setAdminMessage] = useState('');
    const inputFocus = useRef(null);
    let queryKey = ['currentUserMessagesq', {
        currentId: userDetails.userId
    }]
    const { data: currentUsermessages, isLoading } = useQuery(queryKey, GetCurrrentMessagesApi, {
        refetchInterval: 10000
    });
    let queryClient = useQueryClient();
    const { mutate: sendMessageObjfunc } = useMutation('sendMessage', SendMessageApi, {
        onSuccess: () => {
            queryClient.invalidateQueries('currentUserMessagesq');
        }
    })
    const sendMessagetoUser = () => {
        let obj = {
            "userId": userDetails.userId,
            "messageText": adminMessage
        }
        sendMessageObjfunc(obj)
        setTimeout(()=>{
            if(currentUsermessages!=undefined){
                divRef.current.scrollTo({
                    left: 0,
                    top: divRef.current.scrollHeight,
                    behavior: "smooth"
                  });
                  inputFocus.current.focus();
            }
          
        },200);
        setAdminMessage('');
    }
    useEffect(() => {
        if (userDetails?.userId !== ''&&currentUsermessages!==undefined) {
            setTimeout(()=>{
                divRef.current.scrollTo({
                    left: 0,
                    top: divRef.current.scrollHeight,
                    behavior: "smooth"
                });
                inputFocus.current.focus();
            }, 200)
        }
        if (currentUsermessages != undefined) {
            let currentMessageList = currentUsermessages.length - 1
            if (currentMessageList.length > currentUsermessages.length) {
                setTimeout(() => {
                    divRef.current.scrollTo({
                        left: 0,
                        top: divRef.current.scrollHeight,
                        behavior: "smooth"
                    });
                }, 200)
            }
        }

    }, [userDetails.userId, currentUsermessages])
    return (
        <>
            <div className={'col-7 pe-0'}>
                <div style={{ position: "relative", marginTop:"10px ",height:"100%"}}>
                    {currentUsermessages !== undefined && userDetails?.userId !== '' ? <>
                        {userDetails.userName !== '' && <div className={Style.chatHeading}>
                            <span>{userDetails?.userName}</span>
                        </div>}
                        <div className={Style.margintop}>
                            <div className={Style.rightChatwrapper + ' ' + 'col-11'}>
                                <div
                                    ref={divRef}
                                >
                                    {currentUsermessages?.map((message, index) => <div key={index}>
                                        {message?.ReceiverID == '-1' ? <div className={Style.incomingChat}>
                                            <span>
                                                {message?.MessageText}
                                            </span>
                                            <p>
                                                <DateAndTime DateTime={message?.CreatedAt}/>
                                            </p>
                                        </div> :
                                            <div className={Style.outgoingChat}>
                                                <span>
                                                    {message?.MessageText}
                                                </span>
                                                <div className={Style.outgoingPara}>
                                                    <p>{message?.AdminEmail}</p>
                                                    <p>
                                                       <DateAndTime DateTime={message?.CreatedAt}/>
                                                    </p>
                                                </div>
                                            </div>}
                                    </div>)}
                                </div>


                            </div>
                            <div className={Style.chatInputWrapper}>
                                <div className={Style.chatInputinput}>
                                    <textarea
                                        ref={inputFocus}
                                        rows="2"
                                        cols="100"
                                        value={adminMessage}
                                        onChange={(e) => setAdminMessage(e.target.value)}
                                    />
                                    <button
                                        onClick={() => sendMessagetoUser()}
                                    ><img src={SendIcon} alt="send button" /></button>
                                </div>
                            </div>
                        </div>
                    </> : isLoading === true ?
                        <LoaderComponent /> :
                        <div className={Style.emptyValue}>Please select a conversation from the left side</div>}
                </div>
            </div>
        </>
    )
}

export default Messages