// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userListings_actions__ZMStL a, .userListings_actions__ZMStL Button{
    background-color: #fff;
    box-shadow: 0px 0px 4px 1px rgb(139 139 139 / 32%);
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 12px;
    margin:0 6px;
    display: inline;
    color: #4A4A4A;
    text-decoration: none;
    border: 0; 
}
.userListings_userDetails__fSrnm h2{
    font-size: 18px;
    font-weight: 600;
    color: #4A4A4A;
}
.userListings_userDetails__fSrnm h3{
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    color: #4A4A4A;
}
.userListings_userDetails__fSrnm p{
    color: #707070;
}
.userListings_userDetails__fSrnm h1{
    color: #E27614;
    font-size: 14px;
    margin-bottom: 20px;
}
 
.userListings_filterWrapper__0OFXu h2{
    color: #707070;
    font-weight: bold;
    font-size: 24px;
}
.userListings_search__g1Ojr {
    position: relative;
}
.userListings_search__g1Ojr input{
    border: 0;
    box-shadow: 0px 0px 8px 1px #e9e9e9;
    padding: 10px 10px 10px 40px;
}
.userListings_search__g1Ojr svg {
    position: absolute;
    top: 10px;
    font-size: 24px;
    left: 10px;
    color: #4A4A4A;
}
.userListings_filterView__hiDrl img{
    margin: 0 0 0 9px;
    padding: 6px;
    width: 36px;
    height: 33px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tools/userListings/userListings.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kDAAkD;IAClD,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,SAAS;AACb;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,SAAS;IACT,mCAAmC;IACnC,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,UAAU;IACV,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB","sourcesContent":[".actions a, .actions Button{\r\n    background-color: #fff;\r\n    box-shadow: 0px 0px 4px 1px rgb(139 139 139 / 32%);\r\n    border-radius: 10px;\r\n    padding: 5px 20px;\r\n    font-size: 12px;\r\n    margin:0 6px;\r\n    display: inline;\r\n    color: #4A4A4A;\r\n    text-decoration: none;\r\n    border: 0; \r\n}\r\n.userDetails h2{\r\n    font-size: 18px;\r\n    font-weight: 600;\r\n    color: #4A4A4A;\r\n}\r\n.userDetails h3{\r\n    font-size: 14px;\r\n    margin: 0;\r\n    font-weight: 600;\r\n    color: #4A4A4A;\r\n}\r\n.userDetails p{\r\n    color: #707070;\r\n}\r\n.userDetails h1{\r\n    color: #E27614;\r\n    font-size: 14px;\r\n    margin-bottom: 20px;\r\n}\r\n \r\n.filterWrapper h2{\r\n    color: #707070;\r\n    font-weight: bold;\r\n    font-size: 24px;\r\n}\r\n.search {\r\n    position: relative;\r\n}\r\n.search input{\r\n    border: 0;\r\n    box-shadow: 0px 0px 8px 1px #e9e9e9;\r\n    padding: 10px 10px 10px 40px;\r\n}\r\n.search svg {\r\n    position: absolute;\r\n    top: 10px;\r\n    font-size: 24px;\r\n    left: 10px;\r\n    color: #4A4A4A;\r\n}\r\n.filterView img{\r\n    margin: 0 0 0 9px;\r\n    padding: 6px;\r\n    width: 36px;\r\n    height: 33px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `userListings_actions__ZMStL`,
	"userDetails": `userListings_userDetails__fSrnm`,
	"filterWrapper": `userListings_filterWrapper__0OFXu`,
	"search": `userListings_search__g1Ojr`,
	"filterView": `userListings_filterView__hiDrl`
};
export default ___CSS_LOADER_EXPORT___;
