import { axiosInstance } from "./adminDataservice";
import { apiPath } from "../constant/constant";

export const userLogin = async(loginData)=>{
    return await axiosInstance.post(apiPath.adminLogin,loginData)
}
export const DashBoardMatric = async({queryKey})=>{
    const year = queryKey[1].year;
    const startingMonth = queryKey[1].startingMonth;
    const monthCount = queryKey[1].monthCount;
    return await axiosInstance.get(`${apiPath.metricsData}/${year}/${monthCount}/${startingMonth}`)
}
export const GetAllUsers = async()=>{
    return await axiosInstance.get(apiPath.AllUsers,{}) 
}
export const RemoveUserApi = async(userId)=>{
    return await axiosInstance.delete(`${apiPath.AllUsers}/${userId}`)
}
export const UserViewListings = async(userId)=>{
    return await axiosInstance.get(`${apiPath.AllUsers}/${userId}/listings`)
}
export const GetAllMessages = async()=>{
    return await axiosInstance.get(apiPath.allMessages,{});
}
export const GetCurrrentMessagesApi = async({queryKey})=>{ 
    const id = queryKey[1].currentId;
    return await axiosInstance.get(`${apiPath.allMessages}/${id}`)
}
export const SendMessageApi = async(obj)=>{
    return await axiosInstance.post(apiPath.sendMessage,obj)
}
export const ChatViewApi = async(obj)=>{
    return await axiosInstance.post(apiPath.chatView,obj)
}
export const ReportedListingsApi =async()=>{
    return await axiosInstance.get(apiPath.reportedListings,{})
}
export const SingleListingApi = async(listingId)=>{
    return await axiosInstance.get(`${apiPath.singleListing}/${listingId}`);
}
export const SystemNotificationApi = async()=>{
    return await axiosInstance.get(apiPath.systemNotification,{});
}
export const ActivityLogApi =async()=>{
    return await axiosInstance.get(apiPath.activityLog,{});
}
export const RemoveListingApi = async(listingid)=>{
   
    return await axiosInstance.delete(`${apiPath.singleListing}/${listingid}`);
}
export const DismisReportApi = async(reportId)=>{
    return await axiosInstance.delete(`${apiPath.DismissReport}/${reportId}`)
}
export const ReportedUserApi =async()=>{
    return await axiosInstance.get(apiPath.ReportedUsers,{})
}
export const ReportSeenApi =async(reportTypeObj)=>{
    return await axiosInstance.post(apiPath.ReportSeen,reportTypeObj)
}
export const GetSpecificUserApi = async(userId)=>{
    return await axiosInstance.get(`${apiPath.AllUsers}/${userId}`)
}
export const SellerListingApi = async()=>{
    return await axiosInstance.get(apiPath.SellerListings,{})
}
export const BuyerListingApi = async()=>{
    return await axiosInstance.get(apiPath.BuyerListings,{})
}
export const GetAllCategoriesApi= async()=>{
    return await axiosInstance.get(apiPath.GetAllCategoriesList,{});
}
export const EditCategoriesApi= async(obj)=>{
    let CatId = obj.catId;
    let updatedObj = {
        "categoryName": obj.categoryName,
        "languageID": obj.languageID
    }
    return await axiosInstance.patch(apiPath.Categories+"/"+CatId,updatedObj);
}
export const DeleteCategoriesApi= async(CatId)=>{
    return await axiosInstance.delete(apiPath.Categories+"/"+CatId);
}
export const DeleteSubCategoriesApi= async(CatId)=>{
    return await axiosInstance.delete(apiPath.SubCategories+"/"+CatId);
}
export const AddCatAndSubCatApi= async(obj)=>{
    return await axiosInstance.post(apiPath.GetAllCategoriesList,obj);
}
export const EditSubCategoriesApi=async(obj)=>{
    let CatId = obj.catId;
    let updatedObj = {
        "categoryName": obj.categoryName,
        "languageID": obj.languageID
    }
    return await axiosInstance.patch(apiPath.Categories+'/'+CatId,updatedObj)
}
 export const AddSubcategoriesApi = async(obj)=>{
    return await axiosInstance.post(apiPath.AddSubCategories,obj);
 }
export const GetAdminRolesApi=async()=>{
    return await axiosInstance.get(apiPath.getAllAdminRoles,{});
}
export const RegisterNewAdminApi=async(obj)=>{
    return await axiosInstance.post(apiPath.RegisterNewAdmin,obj);
}
export const ForgotPasswordApi =async(obj)=>{
    return await axiosInstance.patch(apiPath.ForgotPassword,obj)
}
export const SetPasswordApi=async(obj)=>{
    return await axiosInstance.post(apiPath.setPassword,obj)  
}
export const AddSubcategoryToMasterApi = async(obj)=>{
    return await axiosInstance.post(apiPath.AddSubCategoriesToMaster,obj);
}
export const AddCategoryToMasterExistingListApi = async(obj)=>{
    return await axiosInstance.post(apiPath.AddCategoryToMasterList,obj)
}
export const AddSubCatToExistingListApi=async(obj)=>{
    return await axiosInstance.post(apiPath.AddSubCatetoExistingList,obj)
}
 //Notification fix
export const GetNotificationSeenStatusApi=async()=>{
    return await axiosInstance.get(apiPath.GetNotificationSeenStatus,{});
}
export const MarkSystemNotificationSeenApi=async()=>{
    return await axiosInstance.post(apiPath.MarkSystemNotificationSeen,{});
}


