// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setyourpassword_forgotWrapper__il3ia {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 15%;
}

.setyourpassword_forgotWrapper__il3ia h3 {
    color: #368C36;
    font-family: 'interBold';
    font-size: 20px; 
}
.setyourpassword_forgotWrapper__il3ia p {
    color: #5C5C5C;
    font-family: 'interNormal';
    font-size: 14px; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/setyourpassword/setyourpassword.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,wBAAwB;IACxB,eAAe;AACnB;AACA;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".forgotWrapper {\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding-top: 15%;\r\n}\r\n\r\n.forgotWrapper h3 {\r\n    color: #368C36;\r\n    font-family: 'interBold';\r\n    font-size: 20px; \r\n}\r\n.forgotWrapper p {\r\n    color: #5C5C5C;\r\n    font-family: 'interNormal';\r\n    font-size: 14px; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotWrapper": `setyourpassword_forgotWrapper__il3ia`
};
export default ___CSS_LOADER_EXPORT___;
