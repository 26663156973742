import React, { useState } from 'react'
import Style from './setyourpassword.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { SetPasswordApi } from '../../services/adminApis';
import { TiTick } from "react-icons/ti";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const SetPassword = () => {
    const [Password, setPassword]=useState('');
    const [ReenterPassword, setReenterPassword]=useState('');
    const [charValidation,setCharValidation]= useState(false);
    const [NumericValidation,setNumericValidation]= useState(false);
    const [searchParams] = useSearchParams();
    let tokenType = searchParams.get('token');
    let navigate = useNavigate();
    const handleChange = (e)=>{
        const {name, value} = e.target;
        if(name==='password'){
            setPassword(e.target.value)
            let numericList = /[0-9]/
            let numericValue = numericList.test(value);
            setCharValidation(value.length>=8)
            setNumericValidation(numericValue)
        }
        if(name==='reenter_password'){
            setReenterPassword(e.target.value)
        }   
    }
    const {mutate:setPassWordFunction} = useMutation('set_password',SetPasswordApi,{
        onSuccess:(data)=>{
          toast.success(data.message);
          navigate('/');
        } 
    })
    const submitForgotPassword=()=>{
        if(Password===ReenterPassword){
            let obj = {
                "token": tokenType,
                "password": Password,
                "confirmPassword": ReenterPassword
              }
              setPassWordFunction(obj)
              
        }
        else{
            toast.error("Please enter correct password.")
        }
    }
    const [EyeopenOne,setEyeopenOne] =useState(false)
    const [EyeopenTwo,setEyeopenTwo] =useState(false)
    return (
        <div className={Style.forgotWrapper}>
            <div className="row">
                <div className="col-md-12 mt-3 mb-4">
                    <h3>Set Password</h3>
                    
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <label className='form_label'>Enter Password</label>
                    <div className='setPass'>
                    <input 
                      type={EyeopenOne===true?"text":"password"}
                      name="password" 
                      className='form_control' 
                      value ={Password}
                      onChange={(e)=>handleChange(e)}
                      style={{paddingRight:"40px"}}

                    />
                    {EyeopenOne === true?<FaEye onClick={()=>setEyeopenOne(false)}/>:<FaEyeSlash onClick={()=>setEyeopenOne(true)}/>}
                    </div>
                    <ul className='required_label'>
                        <li style={{color:charValidation&&"#5BA15B"}}><TiTick style={{fontSize:"16px",color:"#707070"}}/>Minimum 8 characters required.</li>
                        <li style={{color:NumericValidation&&"#5BA15B"}}><TiTick style={{fontSize:"16px",color:"#707070"}}/>Include at least one numeric character.</li>
                    </ul>
                </div>
                <div className='col-12 mb-4'>
                    <label className='form_label'>Re-enter Password</label>
                    <div className='setPass'>
                    <input 
                       type={EyeopenTwo===true?"text":"password"}
                       name="reenter_password" 
                       className='form_control' 
                       value ={ReenterPassword}
                       onChange={(e)=>handleChange(e)}
                       style={{paddingRight:"40px"}}
                    />
                     {EyeopenTwo === true?<FaEye onClick={()=>setEyeopenTwo(false)}/>:<FaEyeSlash onClick={()=>setEyeopenTwo(true)}/>}
                    </div>
                </div>
                <div className='col-12'>
                    <button 
                      className='btn_primary w-100' 
                      onClick={submitForgotPassword} 
                      disabled={charValidation===true&&NumericValidation===true?false:true}
                      style={{backgroundColor:charValidation===false&&NumericValidation===false&&"#A5A5A5"}}
                    >Save</button>
                </div>
            </div>


        </div>
    )
}

export default SetPassword