// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupcomponentStyle_viewListingCard__eMecc{
    padding: 15px;
    color: #4A4A4A;
}
 .popupcomponentStyle_viewListingCard__eMecc {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
    width: 340px!important;
    height: 592px!important;
    border: 3px solid #C5C5C5;
}
.popupcomponentStyle_topfont__mDVQw{
    font-size: 14px; 
}
.popupcomponentStyle_topfontTwo__Lq-I2{
    font-size: 13px;
    font-family: 'interNormal';
    /* display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}
.popupcomponentStyle_viewListingCard__eMecc img{
   height: 166px!important;
   object-fit: contain;
}
.popupcomponentStyle_topLabelImg__f0SQM{
    position: absolute;
    left: -3px;
    top: -3px;
    padding: 5px 6px;
    font-size: 13px;
    border-radius: 0px 0px 5px 0px;
    color: #fff;
    display: flex;
}
.popupcomponentStyle_topLabelImg__f0SQM img{
    width:100%;
    height:  15px!important;
    margin-left: 4px;
}
.popupcomponentStyle_topLabelImg__f0SQM label{
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/popupComponents/popupcomponentStyle.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;CACC;IACG,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,8BAA8B;IAC9B,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B;;;;uBAImB;AACvB;AACA;GACG,uBAAuB;GACvB,mBAAmB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,8BAA8B;IAC9B,WAAW;IACX,aAAa;AACjB;AACA;IACI,UAAU;IACV,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".viewListingCard{\r\n    padding: 15px;\r\n    color: #4A4A4A;\r\n}\r\n .viewListingCard {\r\n    flex-shrink: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    position: relative;\r\n    transition-property: transform;\r\n    display: block;\r\n    width: 340px!important;\r\n    height: 592px!important;\r\n    border: 3px solid #C5C5C5;\r\n}\r\n.topfont{\r\n    font-size: 14px; \r\n}\r\n.topfontTwo{\r\n    font-size: 13px;\r\n    font-family: 'interNormal';\r\n    /* display: -webkit-box;\r\n    max-width: 100%;\r\n    -webkit-line-clamp: 1;\r\n    -webkit-box-orient: vertical;\r\n    overflow: hidden; */\r\n}\r\n.viewListingCard img{\r\n   height: 166px!important;\r\n   object-fit: contain;\r\n}\r\n.topLabelImg{\r\n    position: absolute;\r\n    left: -3px;\r\n    top: -3px;\r\n    padding: 5px 6px;\r\n    font-size: 13px;\r\n    border-radius: 0px 0px 5px 0px;\r\n    color: #fff;\r\n    display: flex;\r\n}\r\n.topLabelImg img{\r\n    width:100%;\r\n    height:  15px!important;\r\n    margin-left: 4px;\r\n}\r\n.topLabelImg label{\r\n    display: flex;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewListingCard": `popupcomponentStyle_viewListingCard__eMecc`,
	"topfont": `popupcomponentStyle_topfont__mDVQw`,
	"topfontTwo": `popupcomponentStyle_topfontTwo__Lq-I2`,
	"topLabelImg": `popupcomponentStyle_topLabelImg__f0SQM`
};
export default ___CSS_LOADER_EXPORT___;
