import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/images/Logo.png';
import style from './login.module.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidateEmail } from '../utils/validateEmail';
import { useMutation } from 'react-query';
import { userLogin } from '../../services/adminApis';
import { jwtDecode } from 'jwt-decode';
import { ThreeDots } from 'react-loader-spinner'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { TokenExpiry } from '../utils/tokenExpiry';

const Login = () => {
     const navigate = useNavigate();
     const [userLoginDetails, setUserLoginDetails] = useState({
          userName: '',
          userPassword: ''
     });
     const [passwordType, setPasswordType] = useState(true);
     const { mutate: loginCall, isLoading } = useMutation('login', userLogin, {
          onSuccess: (data) => {
               const adminCreds = jwtDecode(data.token);
               localStorage.setItem('userDetails',JSON.stringify(adminCreds));
               localStorage.setItem('adminTokenExpiry', JSON.stringify(adminCreds.exp));
               localStorage.setItem('adminToken', JSON.stringify(data.token));
               navigate('/dashboard');
               toast.success("You have successfully logged in.")
          },
     });

     const userHandleChange = (e) => {
          const { name, value } = e.target;
          setUserLoginDetails({ ...userLoginDetails, [name]: value })
     }
     const PasswordShow =()=>{
          setPasswordType(!passwordType)
     }
     const adminLogin = () => {
          if (!userLoginDetails.userName) {
               toast.error("Email cannot be empty.");
               return;
          }
          if (!userLoginDetails.userPassword) {
               toast.error("Password cannot be empty.");
               return;
          }
          if (!ValidateEmail(userLoginDetails.userName)) {
               toast.error('Please enter a valid email.');
               return;
          }
          loginCall({
               email: userLoginDetails.userName,
               password: userLoginDetails.userPassword
          })
     }
     useEffect(()=>{
          if(!TokenExpiry(localStorage.getItem('adminTokenExpiry'))){
               navigate('/dashboard');
          }
          else{
               navigate('/');
          }
     },[])
     return (
          <div className={style.loginWrapper}>
               <div className='row'>
                    <div className='col-6'>
                         <div className={style.logo}>
                              <img src={Logo} alt="logo" />
                         </div>
                    </div>
                    <div className='col-6'>
                         <div className={style.adminpanel}>
                              <h1>Admin Panel</h1>
                              <div className={style.inputWrapper}>
                                   <label>Email</label>
                                   <input
                                        type="text"
                                        name="userName"
                                        value={userLoginDetails.userName}
                                        onChange={(e) => userHandleChange(e)}
                                   />
                              </div>
                              <div className={style.inputWrapper}>
                                   <label>Password</label>
                                   <div className={style.inputPassword}>
                                   <input
                                        type={passwordType === true ? "password" : "text"}
                                        name="userPassword"
                                        value={userLoginDetails.userPassword}
                                        onChange={(e) => userHandleChange(e)}
                                        style={{
                                             paddingRight:"34px"
                                        }}
                                   />
                                    {passwordType === true ? <FaEyeSlash onClick={PasswordShow} /> :
                                   <FaEye onClick={PasswordShow} />}
                                   </div>
                              </div>
                              <div className={style.greentextBold}><Link  to="/forgotpassword">Forgot password?</Link></div>
                              <button onClick={adminLogin}>Login</button>
                              {isLoading && <ThreeDots
                                   visible={true}
                                   height="30"
                                   width="50"
                                   color="#4fa94d"
                                   radius="9"
                                   ariaLabel="three-dots-loading"
                                   wrapperStyle={{}}
                                   wrapperClass=""
                              />}
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default Login;