import React, { useMemo } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import ListingStatus from '../listingStatus/listingStatus';
import DateAndTime from '../DateAndTime/date_time';
import NoImage from '../../assets/images/no_image.jpg';

const SellerGridView = ({ sellerData, searchInput}) => {
    let InputValLower = searchInput.toLowerCase();
    const getFilterArray = useMemo(() => {
        let filterdta = sellerData?.filter((item) => {
            const timestampDate = new Date(item?.PostedOn);
            // Format the date as a string with date and time
            const formattedTimestamp = timestampDate.toLocaleString('en-GB');
            let removeComma = formattedTimestamp.split(',');
            let reArrangeDate = removeComma[0]+' '+removeComma[1]
            return   item.CategoryName_EN?.toLowerCase().includes(InputValLower) ||
            item.CategoryName_MM?.toLowerCase().includes(InputValLower) ||
            item.City?.toLowerCase().includes(InputValLower) ||
            item.DeliveryStatus?.toLowerCase().includes(InputValLower) ||
            item.Description?.toLowerCase().includes(InputValLower) ||
            item.ListingStatus?.toLowerCase().includes(InputValLower) ||
            item.PostedBy?.toLowerCase().includes(InputValLower) ||
            item.PostedOn?.toLowerCase().includes(InputValLower) ||
            item.PriceMax?.toLowerCase().includes(InputValLower) ||
            item.PriceMin?.toLowerCase().includes(InputValLower) ||
            item.Quantity?.toLowerCase().includes(InputValLower) ||
            item.SiteInspectionStatus?.toLowerCase().includes(InputValLower) ||
            // item.Subcategory?.toLowerCase().includes(InputValLower) ||
            item.Township?.toLowerCase().includes(InputValLower) ||
            item.Unit?.toLowerCase().includes(InputValLower) ||
            item.ListingID?.toString().includes(searchInput)||reArrangeDate.toLowerCase().includes(InputValLower) ||
            item.SubcategoryName_EN?.toLowerCase().includes(InputValLower) ||
            item.SubcategoryName_MM?.toLowerCase().includes(InputValLower) 
        })
        return filterdta
    }, [InputValLower,sellerData, searchInput])
    return (
        <div className="gridViewWrapper my-4">
            <div className='row gridScroll'>
                {getFilterArray!==undefined&&getFilterArray.length>0?getFilterArray?.map((seller, index) => <div className='col-md-3 mb-4' key={index} style={{ position: "relative" }}>
                    <div className="viewListing">
                        <div className='unPublishedWrapper text-center'>
                            <div style={{
                                left: "15px",
                                top: "3px",
                                position: "absolute",
                                width: "100%"
                            }}>
                                <ListingStatus
                                    listingStatus={seller?.ListingStatus}
                                />
                            </div>
                            <div className='row border-bottom py-2 mt-3'>
                                <div className='col-6'><h5>{seller?.ListingID}</h5></div>
                                <div className='col-6'><h5>
                                        <DateAndTime DateTime={seller?.PostedOn}/>
                                    </h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{seller?.PostedBy}</h5></div>
                                <div className='col-6'><h5>{seller?.Images!==''?`${seller?.Images.split(',').length} image${seller?.Images.split(',').length>1?"'s":''}`:''}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12'>
                                    <Carousel 
                                      className='listingSlider'
                                      interval={null}
                                      indicators={false}
                                      controls={seller?.Images!==''&&seller?.Images!==null&&seller?.Images.split(',').length>1?true:false} 
                                    >
                                    {seller?.Images!==''&&seller?.Images!==null?seller?.Images.split(',').map((item,index)=><Carousel.Item interval={1000} key={index}>
                                        <img src={item} alt="aa" />
                                    </Carousel.Item>):
                                    <Carousel.Item>
                                        <img src={NoImage} alt="aa" />
                                    </Carousel.Item>
                                    }
                                    </Carousel>

                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                {/* <div className='col-6'><h5>{seller?.Subcategory}</h5></div>
                                <div className='col-6'><h5>{seller?.Category}</h5></div> */}
                                <div className='col-6'><h5>   {seller?.CategoryName_EN!==''&&seller?.CategoryName_EN!==null?seller?.CategoryName_EN:seller?.CategoryName_MM}</h5></div>
                                <div className='col-6'><h5>{seller?.SubcategoryName_EN!==''&&seller?.SubcategoryName_EN!==null?seller?.SubcategoryName_EN:seller?.SubcategoryName_MM}</h5></div>
                            
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>Ks.{seller?.PriceMax}/{seller?.Unit}</h5></div>
                                <div className='col-6'><h5>{seller?.Quantity} {seller?.Unit}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-md-12'>
                                    <p>{seller?.Description}</p>
                                </div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{seller?.Township}</h5></div>
                                <div className='col-6'><h5>{seller?.City}</h5></div>
                            </div>
                            <div className='row border-bottom py-2'>
                                <div className='col-6'><h5>{seller?.DeliveryStatus}</h5></div>
                                <div className='col-6'><h5>{seller?.SiteInspectionStatus}</h5></div>
                            </div>
                        </div>
                    </div>
                </div>):<div>No data found.</div>}
            </div>
        </div>
    )
}

export default SellerGridView;