import React from 'react'
import Style from './backup.module.css';
 

const Backup = () => {
   
    return (
        <div className={Style.backup}>
            <div className="row">
                <div className="col-md-12 mb-4 mt-3"> 
                    <div className="col-md-8"><h2>backup</h2></div>
                </div> 
                <div className="col-md-12 mt-4">

                </div>
            </div>
        </div>
    )
}

export default Backup