import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { activeCategoryPopup } from '../../redux/Actions';
import { GoPlus } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircle } from "react-icons/io";
import { useMutation, useQueryClient } from 'react-query';
import { AddCatAndSubCatApi } from '../../services/adminApis';
import { toast } from 'react-toastify';
import { MdOutlineDeleteForever } from "react-icons/md";

const AddCategory = () => {
    const CategoryPopup = useSelector((state) => state.activeCategory);
    let queryClient = useQueryClient();
    const dispatch = useDispatch();
    //setCuurentName({})
    const [subCatList, setSubCatList] = useState([]);
    const [addCat, setAddCat] = useState({
        nameEN: null,
        nameMM: null
    })
    const handleChangeCat =(e)=>{
         const {name, value}= e.target;
         setAddCat({...addCat,[name]:value===''?null:value})
    }
    const {mutate:mutateFunction} = useMutation('addCat_addSub',AddCatAndSubCatApi,{
        onSuccess:(data)=>{
            toast.success(data.message)
            dispatch(activeCategoryPopup(false))
            setAddCat({
                nameEN: null,
                nameMM: null
            })
            setSubCatList([])
            queryClient.invalidateQueries('getAllCategories');
        },
        onError:(data)=>{
            toast.error(data.message)
        }
    });
    const addToMaterList=()=>{
            if((addCat.nameEN !==''&&addCat.nameEN !==null)||(addCat.nameMM !==''&&addCat.nameMM !==null)){
                let obj ={
                    category:addCat,
                    subcategories:subCatList
                }
                mutateFunction(obj)
            }
           else{
               toast.error("Please enter category on English or Burmese.")
           }
    }
    const addNewSubCategory =()=>{
         setSubCatList([...subCatList,
            {
                subCategoryNameEN: null,
                subCategoryNameMM: null
            }
         ])  
   
    }
    const handleInputSubCatChange =(index,value,language)=>{
          const NewInputValues = [...subCatList]
          if(language === 'en'){
            if(value===''){
                NewInputValues[index].subCategoryNameEN=null;
            }
            if(value!==''){
                NewInputValues[index].subCategoryNameEN=value;
            }
          }
          else{
            if(value===''){
                NewInputValues[index].subCategoryNameMM=null;
            }
            if(value!==''){
                NewInputValues[index].subCategoryNameMM=value;
            }
          }
          setSubCatList(NewInputValues);
    }
    const subCatdeleteByone =(index)=>{
        let filterSubCatArray = subCatList?.filter((item,indexId)=>indexId!==index);
        setSubCatList(filterSubCatArray);
    }
    const removeAllSubCategory=()=>{
        setSubCatList([]);
    }
    return (
        <Modal
            className="modalStyleCat text-center"
            show={CategoryPopup}
            onHide={() => {
                dispatch(activeCategoryPopup(false))
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <IoMdCloseCircle className="modelClose" onClick={() => {
                    dispatch(activeCategoryPopup(false))
                    }} />
                <div className="mt-4 mb-3">
                    <h4 className='db-pop-title'>Add New Category to Master List</h4>
                    <div className='row mb-4' style={{ textAlign: "left" }}>
                        <div className='col-12'><h4 className='catTitle'>Category</h4></div>
                        <div className='col-6 catInput'>
                            <label>Label in English</label>
                            <input 
                               type="text" 
                               name="nameEN"
                               value={addCat.nameEN===null?'':addCat.nameEN} 
                               onChange={(e)=>handleChangeCat(e)}
                            />
                        </div>
                        <div className='col-6 catInput'>
                            <label>Label in Burmese</label>
                           <input 
                               type="text"
                               name="nameMM"
                               value={addCat.nameMM===null?'':addCat.nameMM}
                               onChange={(e)=>handleChangeCat(e)}
                            />
                        </div>
                    </div>
                    {subCatList.length>0&&<div className='row mt-4' style={{ textAlign: "left" }}>
                        <div className='col-12'><h4 className='catTitle'>Subcategory</h4></div>
                        {subCatList?.map((sub,index)=><div className='row' key={index}>
                            <div className='col-6 catInput mb-4'>
                                {index===0&&<label>Label in English</label>}
                                <input 
                                   type="text" 
                                   value={sub.subCategoryNameEN}
                                   onChange={(e) => handleInputSubCatChange(index, e.target.value,'en')}
                                 />
                            </div>
                            <div className='col-6 catInput mb-4' style={{position:"relative"}}>
                              {index===0&&<label>Label in Burmese</label>}
                                <input 
                                   type="text" 
                                   value={sub.subCategoryNameMM}
                                   onChange={(e) => handleInputSubCatChange(index, e.target.value,'mm')}
                                />
                              <MdOutlineDeleteForever 
                                className='deleteByone' 
                                onClick={()=>subCatdeleteByone(index)}
                               />
                            </div>
                            
                        </div>)}
                    </div>}
                    <div className='row' >
                        <div className='col-6' style={{ textAlign: "left" }}>
                            <button 
                               className='addNewSubcat'
                               onClick={addNewSubCategory}
                            ><GoPlus style={{ fontSize: "20px" }} /> Add New Subcategory</button>
                        </div>
                        {subCatList.length>0&&<div className='col-6' style={{ textAlign: "right" }}>
                            <button 
                               className='removeSubcat'
                               onClick={removeAllSubCategory}
                            ><RiDeleteBin6Line style={{ fontSize: "18px" }} /> Remove All Subcategories</button>
                        </div>}
                    </div>
                    <div className='row mt-5' >
                        <div className='col-12' >
                            <button 
                              className='addMasterList' 
                              style={{ color: "#fff"}}
                              onClick={addToMaterList}
                            >Add to Master List <FaArrowRight style={{ fontSize: "16px" }} /></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddCategory