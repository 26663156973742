import { useState } from 'react';
import style from './userListings.module.css';
import { Link } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';
import { IoMdSearch } from "react-icons/io";
import SellerGridView from '../../../components/sellerListing/sellergridView';
import SellerTableView from '../../../components/sellerListing/sellerTableView';
import GridViewGrey from '../../../assets/images/GridviewGrey.svg';
import ListViewGrey from '../../../assets/images/ListviewGrey.svg';
import GridviewGreen from '../../../assets/images/GridviewGreen.svg';
import ListViewGreen from '../../../assets/images/ListviewGreen.svg';

const SellerListings = ({sellerData}) => {
    const [ShowsellerGridView, setShowSellerGridView] = useState(false);
    const [SearchValue, setSearchValue] = useState('')
    return (
        <div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className={style.filterWrapper + ' ' + 'row'}>
                        <div className="col-md-4">
                            <Form>
                                <div className={style.search}>
                                    <FormControl
                                        placeholder="Search for any data..."
                                        onChange={(e)=>setSearchValue(e.target.value)}
                                    />
                                    <IoMdSearch />
                                </div>
                            </Form>
                        </div>
                        <div className={style.filterView + ' ' + 'col-md-8 text-end'}>
                            <Link onClick={()=>setShowSellerGridView(true)}>
                                <img 
                                   src={ShowsellerGridView===true?GridviewGreen:GridViewGrey}
                                   alt="Grid View" 
                                   style={{backgroundColor:ShowsellerGridView===true?"#DFDFDF":"unset"}}
                                />
                            </Link>
                            <Link onClick={()=>setShowSellerGridView(false)}>
                                <img 
                                   src={ShowsellerGridView===true?ListViewGrey:ListViewGreen} 
                                   alt="List View" 
                                   style={{backgroundColor:ShowsellerGridView===true?"unset":"#DFDFDF"}}
                                /></Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {ShowsellerGridView?
                    <SellerGridView 
                        sellerData={sellerData}
                        searchInput={SearchValue}
                    />:
                    <SellerTableView
                        sellerData={sellerData}
                        searchInput={SearchValue}
                    />}
                </div>
            </div>
        </div>
    )
}

export default SellerListings