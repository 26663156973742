export const MonthData = [
    {
        name:'Jan',
        id:1
    },
    {
        name:'Feb',
        id:2
    },
    {
        name:'Mar',
        id:3
    },
    {
        name:'Apr',
        id:4
    },
    {
        name:'May',
        id:5
    },
    {
        name:'Jun',
        id:6
    },
    {
        name:'Jul',
        id:7
    },
    {
        name:'Aug',
        id:8
    },
    {
        name:'Sep',
        id:9
    },
    {
        name:'Oct',
        id:10
    },
    {
        name:'Nov',
        id:11
    },
    {
        name:'Dec',
        id:12
    },
]